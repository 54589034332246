import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { FieldWithError } from '_common';
import { KeyFill, PersonCircle } from 'react-bootstrap-icons';
import { accountService } from '_services';

const ResetPassword = (props: any) => {

  const [isComplete, setIsComplete] = useState<Boolean>(false);

  const initialValues: any = {
    // @TODO: remove pre-filled data for production!
    username: '',
    password: '',
    confirmPassword: '',
    emailAddress: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('No password provided.')
      .min(3, 'Password is too short - should be 3 chars minimum.'), //@TODO: increase to 8, alphanumeric 

    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), undefined], 'Passwords must match'),

  });

  const onSubmit = async (data: any, actions: any) => {
    const payload = {
      authId: props.match.params.authID,
      password: data.password,
      repeatPassword: data.confirmPassword,
      username: data.username,
      emailAddress: data.username,
    };
    actions.setSubmitting(true);
    try {
      await accountService.resetPassword(payload);
      setIsComplete(true);
    } catch (err) {
      actions.setSubmitting(false);
      actions.setStatus(`Password reset failed: ${err}`);
    }

  }

  useEffect(() => {

  }, [isComplete])


  return (
    <div className="container"><div className="row justify-content-center"><div className="col col-md-6">
      <div className="row justify-content-center top-banner">
        <div className="py-5 text-center">
          <h2>Set Your Password</h2>
          {(isComplete) ?
            <div>Your password has been succesfully reset.</div>
            :
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, isSubmitting, errors, touched, status }) => {
                return (
                  <Form className="form">
                    <div className="row">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName='username'
                        placeholder='Enter User Name'
                        icon={(<PersonCircle />)}
                      />
                    </div>

                    <div className="row">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName='password'
                        icon={(<KeyFill />)}
                        placeholder='Enter Password'
                        type='password'
                      />
                    </div>

                    <div className="row">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName='confirmPassword'
                        icon={(<KeyFill />)}
                        placeholder='Enter Password Again'
                        type='password'
                      />
                    </div>

                    <div className="btnWrapper">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn btn-primary btn-md green"
                      >Confirm</button>
                    </div>

                    {status &&
                      <div className='alert'>{status}</div>
                    }
                  </Form>
                )
              }}
            </Formik>
          }
        </div>
      </div>
    </div>
    </div></div>

  );
}

export { ResetPassword }