import * as React from 'react';

const CreditCardOnFile = (props: any) => {

  const { rows } = props;

  return (
    <React.Fragment>
      <div className="card mt-2">
        <h4 className="card-header">Credit Card On File</h4>
        <div className="card-body">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Last 4 Digits</th>
                <th>Expiration Date</th>
              </tr>
            </thead>
            <tbody>
              {rows && rows.length > 0 ? (rows.map((row:any, idx: number) => 
              <tr key={idx}>
                <td>{row.ccLast4}</td>
                <td>{row.ccExpDate}</td></tr>)) : (
                  <tr><td>No Data</td></tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment >
  );
};

export { CreditCardOnFile };
