/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import { ISubscriber } from '_interfaces';
import { ModalEmailRegistration } from '_components/_modals';

const HowItWorks: React.FunctionComponent<{account: ISubscriber | null}> = ({ account }) => {
  const [showEmailForm, setShowRegForm] = useState(false);
  const handleClose = () => setShowRegForm(false);
  const onClick = () => setShowRegForm(true);

  return (
    <>
      <ModalEmailRegistration
        show={showEmailForm}
        handleClose={handleClose}
      />

      {account && account.activeSubscription ? (<></>) : (
        <section id="how-it-works" className="mt-5">

          <div className="p-5 how-it-works">
            <h3 className="text-primary">How it Works</h3>
            <ol className="list-group m-3">
              {!account && <li><span onClick={onClick} className="how-it-works-register">Register</span> for free to see deals now.</li>}
              <li>{account && !account.activeSubscription ? <><a href="/subscription" className="text-primary fw-bold">Subscribe now</a> at $5 per month* for a limited time</> : 'Subscribe for a small annual fee to purchase deals.'}</li>
              <li>Get immediate access to search and purchase discounted hotel stays, auto rentals, movie and theme park tickets, sports, and concert tickets.</li>
              <li>365-day telephone customer support available for assistance and cancellations.</li>
              <li>Start saving hundreds of dollars.</li>
            </ol>
            {account && !account.activeSubscription && <span className="billed-footnote">* Billed at an annual rate</span>}
          </div>
        </section>
      )}
    </>
  );
}

export { HowItWorks }