import React, { useState } from 'react';
import { ModalRegistrationRequired } from '_components';

import { 
  Jumbotron,
  HotelsAndVillas,
  MovieTickets,
  HowItWorks,
  SignUpNow,
  SportsTickets,
  Testimonials,
  ThemeParks,
  Concerts,
  Subscribe,
  LoggedInMenu,
} from '_components';
import { ISubscriber } from '_interfaces';

const Main:React.FunctionComponent<{account:ISubscriber | null, showPrice?: boolean}> = ({account, showPrice}) => {
  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const loggedIn = account !== null;

  return(
    <article>

    
      <ModalRegistrationRequired show={show} handleClose={handleClose}/>

      {loggedIn ? <><LoggedInMenu account={account}/>{account?.activeSubscription === false ? <Subscribe/> : ''}</> : <Jumbotron showPrice={showPrice}/>}

      <HotelsAndVillas handleShow={ handleShow } account={account}/>

      <MovieTickets handleShow={ handleShow } account={account}/>

      <SportsTickets handleShow={ handleShow } account={account}/>

      <ThemeParks handleShow={ handleShow } account={account}/>

      <Concerts handleShow={ handleShow } account={account}/>

      <HowItWorks account={account}/>

      {!loggedIn && <Testimonials/>}

      <SignUpNow account={account}/>
      
    </article>
  );
}

export { Main }
