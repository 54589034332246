import React from 'react';
import { withRouter } from 'react-router';

const _SubscribeNowButton = (props: any) => {
  return(
    <button type="button" className="btn bg-primary btn-lg px-4 gap-3 text-white input-block-level form-control" onClick={() => {props.history.push('/subscription')}}>Subscribe Now</button>
  )
}

const SubscribeNowButton = withRouter(_SubscribeNowButton);
export { SubscribeNowButton }