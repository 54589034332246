import axios from 'axios';
import { config } from 'config';
import { 
  ICredentialsRegistration,
  ISubscriber,
  ICreditCard,
  IBillTo,
  ISubscription,
  ICouponDiscount,
  IOffer,
} from '_interfaces';
import { authenticationService } from './AuthenticationService';
import fpjs from 'fingerprint';

class AccountService {
  constructor(
    protected apiBaseUrl: string
  ) {

  }

  /*
  -- cannot use simple email registration because
  -- password is required for logging in.
  async register(emailAddress: string): Promise<any> {
    const url = `${this.apiBaseUrl}/newsletter/registration`;
    const response = await axios.post(url, {emailAddress});
    return response;
  }
  */

  async register(credentials: ICredentialsRegistration): Promise<any> {
    const url = `${this.apiBaseUrl}/subscriber/registration`;
    try {
      const response = await axios.post(url, credentials);
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data;
    } catch (err) {
      throw Error(err.message);
    }
  }
  
  async getAccount(): Promise<any> {
    const token = authenticationService.getToken();
    if (token === '' || token === null) {
      return null;
    }
    
    const url: string = `${this.apiBaseUrl}/subscriber/account`;
    try {
      const response = await axios.get(url, authenticationService.getAuthHeader());
      if (!response.data || response.data.error) {
        throw Error(response.data.error);
      }
      response.data.account.activeSubscription = response.data.subscription.isActive;
      return response.data;
    } catch (err) {
      console.error('error getting account', err.message);
      return null;
    }
  }

  async updateAccount(subscriber: ISubscriber): Promise<any> {
    const token = authenticationService.getToken();
    if (token === '' || token === null) {
      throw Error('Cannot update without a token');
    }
    const url: string = `${this.apiBaseUrl}/subscriber/account`;
    try {
      const account = await axios.put(url, subscriber, authenticationService.getAuthHeader());
      if (!account.data || account.data.error) {
        throw Error(`error updating: ${account.data.error}`);
      }
      localStorage.setItem('acct', JSON.stringify(account.data.account));
      return account.data;
    } catch (err) {
      console.error('error updating account', err.message);
      return null;
    }
  }

  getAccountFromLocal() : ISubscriber | null {
    const acct = localStorage.getItem('acct');
    if (acct === null) {
      return null;
    } else {
      return JSON.parse(acct);
    }
  }

  async pay(offerId: number, creditCard: ICreditCard, billTo: IBillTo) {
    const url = `${this.apiBaseUrl}/subscriber/pay`;

    const data = {
      planId: offerId,
      creditCard: creditCard,
      billTo: billTo,
    }

    try {
      const response = await axios.post(url, data, authenticationService.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data;
    } catch (err) {
      throw Error(err);
    }
  }

   async getGatewayInfo(): Promise<any> {
    const url = `${this.apiBaseUrl}/subscriber/pay/gateway-info`;

    try {
      const data: any = await axios.post(url, {}, authenticationService.getAuthHeader());
      if (data.error) {
        throw new Error(`api failure: ${data.error.message}`);
      }
      return data.data;
    }
    catch (err) {
      console.error('failed getting getIntent', err);
    }
  }

  async requestResetPassword(username: string) {
    const fp = await this.getFP();
    const url = `${this.apiBaseUrl}/subscriber/request-reset-password?fg=${fp.visitorId}`;
    const data = {
      //data:{username:any,siteId:any,ip:any,fingerprint:any}
      username: username,
      ip: 0,
      siteId: config.aiqOfferId,
    }

    try {
      const response = await axios.post(url, data, authenticationService.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err) {
      throw Error(err);
    }
  }

  async resetPassword(data: {authId: string, password: string, repeatPassword: string}) {
    const fp = await this.getFP();
    const url = `${this.apiBaseUrl}/subscriber/reset-password?fg=${fp.visitorId}`;
    try {
      const response = await axios.post(url, data, authenticationService.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err) {
      throw Error(err);
    }
  }

  async confirmEmail(authId: string) {
    const fp = await this.getFP();
    const url = `${this.apiBaseUrl}/subscriber/confirm-email?fg=${fp.visitorId}`;
    try {
      const response = await axios.post(url, {authId}, authenticationService.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err) {
      throw Error(err);
    }
  }

  async getFP(): Promise<any> {
    const fp = await fpjs.load();
    const result = await fp.get();
    return result;
  }

  getProfiles(): Promise<any> {
    const url = `${this.apiBaseUrl}/subscriber/payment/profile`;
    return this.getApiPromise(url);
  }

  getSubscriptions(): Promise<any> {
    const url = `${this.apiBaseUrl}/subscriber/subscriptions`;
    return this.getApiPromise(url);
  }

  getPayments(): Promise<any> {
    const url = `${this.apiBaseUrl}/subscriber/payments`;
    return this.getApiPromise(url);
  }

  getOffer(): Promise<IOffer> {
    const offerId = config.aiqOfferId;
    const url = `${this.apiBaseUrl}/subscriber/offer/${offerId}`;
    return this.getApiPromise(url);
  }  

  async getCouponInfo(code: string): Promise<ICouponDiscount> {
    try {
      const url = `${this.apiBaseUrl}/subscriber/pay/coupon-info`;
      const response: any = await axios.post(url, {code}, authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data as ICouponDiscount;
    } catch (error) {
      throw Error(error);      
    }
  }

  cancelSubscription(subscription: ISubscription): Promise<any> {
    const url = `${this.apiBaseUrl}/subscriber/subscription/${subscription.id}`;
    return new Promise((resolve, reject) => {
      axios.delete(url, authenticationService.getAuthHeader())
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        })
    });
  }

  // convenience method, GET method
  private async getApiPromise<T>(url: string): Promise<T> {

    try {
      const response = await axios.get(url, authenticationService.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data as T;
    } catch (err: any) {
      throw Error(err);
    }

  }  
}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/${config.siteUID}`;
const accountService = new AccountService(apiBaseUrl);
export { accountService }