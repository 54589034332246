import React, { useState } from 'react';
import { ITicket, ISectionProps, ITicketModal, ISubscriber } from '_interfaces';
import { ModalSubscriptionRequired } from '_components/_modals';
import { Section } from '_constants'
import { SectionAllLink } from '..';
import { dataService } from '_services';
import { Blurb } from '_common';

interface IAccountProps {
  ticket: ITicket,
  account: ISubscriber | null
}

const SportsBox: React.FunctionComponent<IAccountProps> = ({ ticket, account }) => {
  return (
    <div className="ticket-container">
      <div className="ticket-poster"><img className="img-fluid" src={ticket.thumbnail} alt={ticket.title} /></div>
      {account !== null && ticket.overlayText &&
        <div className="ticket-overlay-container">
          <div className="ticket-overlay-title">{ticket.overlayText?.title}</div>
          <div className="ticket-overlay-subtitle">{ticket.overlayText?.subtitle}</div>
          <div className="ticket-overlay-subtitle-2">{ticket.subTitle1}</div>
        </div>
      }

      {account === null &&
        <div className="pricing">
          <span className="percent-off">{ticket.percentOff}% <br />off</span>
        </div>
      }

      {account !== null &&
        <div className="pricing hotel discounted">
          <span className="small"><del>${ticket.regularPrice}</del></span>
          <span className="percent-off">${ticket.discountPrice}</span>
        </div>
      }

    </div>
  );
}


const SportsTickets: React.FunctionComponent<ISectionProps> = ({ handleShow, account }) => {
  const [item, setItem] = useState<ITicketModal>({} as ITicketModal)

  const tickets: ITicket[] = dataService.getSports();

  const loggedIn = account !== null;

  const handleShowTicket = (ticket: ITicket) => {
    setItem({ ticket: ticket, show: true })
  };

  const handleCloseTicket = () => {
    setItem({ ticket: {} as ITicket, show: false });
  }

  const onTicketClick = (e: any, ticket: ITicket) => {
    if (loggedIn) {
      if (account?.activeSubscription) {
        window.location.href = ticket.dealUrl ? ticket.dealUrl : '/';
      } else {
        handleShowTicket(ticket)
      }
    } else {
      handleShow(e);
    }
  }

  return (
    <section id="sports-section">

      <ModalSubscriptionRequired item={item} onHide={handleCloseTicket} />

      <div className="container">
        {loggedIn &&
          <SectionAllLink
            mainTitle='Sports Tickets'
            linkTitle='View All Sports Ticket Deals &gt;'
            onClick={() => setItem({ ticket: {} as ITicket, show: true })}
            section={Section.Sports}
            account={account}
          />
        }
        <div className={`row ${(loggedIn ? 'justify-content-center' : '')}`}>
          {!loggedIn &&
            <div className="col-md-5 d-flex align-items-center mb-3">
              <Blurb handleShow={handleShow} text="See Latest Sports Deals" arrowDirection="right">
                <h4>Sports Tickets</h4>
                <span className="fw-bold">Save 20% and get Preferred Access&trade;</span> to tickets not available
                to the public.<br />
                <span className="fw-bold">Save an average of $30 to $100 per person.<br />
                  $400 per year.</span>
              </Blurb>
            </div>
          }
          {tickets && tickets.length > 0 ? (
            tickets.map((ticket: ITicket, i: number) => {
              if (loggedIn && i > 0) {
                // ignore others if logged in. do nothing.
                return <React.Fragment key={`ticket-${i}`}></React.Fragment>
              } else {
                return <React.Fragment key={`ticket-${i}`}><div className={`col-md-${loggedIn ? '6' : '3'}`} onClick={(e) => { onTicketClick(e, ticket) }}><SportsBox account={account} ticket={ticket} /></div><div className="xs-visible mb-4 col-sm-1"></div></React.Fragment>
              }
            })
          ) : (
            <div>No Data</div>
          )}
        </div>
      </div>
    </section>
  );

}

export { SportsTickets }