import React from 'react';

const TermsAndConditionsUse = () => {
  return(
<div>

<h2>TERMS AND CONDITIONS OF USE</h2>
<p><span className="fw-bold">1. User's Acknowledgment and Acceptance.</span><br/>
</p><p>EnjoyTheTown provides its services via a partnership with Entertainment Benefits Group, LLC d/b/a Member Deals (referred to as "us", "we" or "our") which provides the website www.memberdeals.com and various related services (together referred to as this "Website") subject to your compliance with all the terms, conditions and notices contained or referenced herein (collectively, these "Terms of Use"), as well as any other written agreement between us (or your company). In addition, when using particular services or materials on this Website, users shall be subject to any posted guidelines or rules applicable to such services or materials that may contain terms and conditions in addition to the terms and conditions contained in these Terms of Use. All such guidelines or rules are hereby incorporated by reference into these Terms of Use, including but not limited to the Terms and Conditions of Sale and Privacy Policy.
</p><p>BY REGISTERING AN ACCOUNT, PLACING AN ORDER AND/OR OTHERWISE USING THIS WEBSITE, YOU AGREE TO BE BOUND BY THESE TERMS OF USE. IF YOU DO NOT WISH TO BE BOUND BY THE THESE TERMS OF USE, PLEASE EXIT THE WEBSITE NOW. YOUR REMEDY FOR DISSATISFACTION WITH THIS WEBSITE, OR ANY PRODUCTS, SERVICES, CONTENT OR OTHER INFORMATION AVAILABLE ON OR THROUGH THIS WEBSITE, UNLESS OTHERWISE SET FORTH IN THE WEBSITE’S TERMS AND CONDITIONS, IS TO STOP USING THE WEBSITE AND/OR THOSE PARTICULAR PRODUCTS OR SERVICES. YOUR AGREEMENT WITH US REGARDING COMPLIANCE WITH THESE TERMS AND CONDITIONS OF USE BECOMES EFFECTIVE IMMEDIATELY UPON COMMENCEMENT OF YOUR USE OF THIS WEBSITE.
</p><p>These Terms of Use are effective as of the date described at the bottom of these terms and conditions. We reserve the right to change these Terms of Use from time-to-time without notice to you. You acknowledge and agree that it is your responsibility to review this Website and these Terms of Use periodically and to be aware of any modifications. Your continued use of this Website after such modifications will constitute your acknowledgment of the modified Terms of Use and your agreement to abide and be bound by the modified Terms of Use.
</p><p>As used in these Terms of Use, references to a "Related Company" or “Related Companies” include our direct or indirect owners, subsidiaries and affiliated companies. You may be reaching this Website through the URL first mentioned above or through a co-branded, white-label or marketing affiliate program (each a “Marketing Affiliate”). However, Marketing Affiliates, and their respective websites, products and/or services, are not under our ownership, operation or control. Marketing Affiliates are independent contractors. Marketing Affiliates operate their respective websites and businesses subject to different security protocols, privacy policies and terms and conditions than the ones found on this Website. Links from Marketing Affiliates do not constitute an endorsement by us of the content of their websites nor of their business practices. We are not responsible for examining or evaluating, and we do not warrant or claim responsibility in any way for, the products, services, offerings or business practices of any Marketing Affiliates, or the content of their websites.</p>

<p><span className="fw-bold">2. Description of Services.</span><br/>
We make various services available on this Website including, but not limited to, the ability to create a user account and/or learn about and/or purchase tickets or vouchers for a third-party’s events, attractions, tours or hotels. Fees for the various services are described elsewhere in these Terms of Use or this Website. You are solely responsible for providing, at your own expense, all equipment necessary to use the services, including a computer and modem, and your own Internet access (including payment of telephone or internet service fees associated with such access).</p>
<p>We reserve the sole right to either modify or discontinue the Website, including any features thereon, at any time with or without notice to you. We shall not be liable to you or any third party should we exercise such right. Modifications may include, but are not limited to, changes in the product or service offerings or pricing. Any new features that augment or enhance the then-current services on this Website shall also be subject to these Terms of Use.</p>
<p>You understand and agree that temporary interruptions of the services available through this Website may occur as normal events. You further understand and agree that we have no control over third party networks you may access in the course of your use of this Website; and, therefore, delays or disruption of other network transmissions are completely beyond our control.</p>
<p>You understand and agree that the services available on this Website, including but not limited to any editorial services or your ability to create a user account, are provided "AS IS" and that we assume no responsibility for the timeliness, deletion, mis-delivery, or failure to store any user communications or personalization settings.</p>

<p><span className="fw-bold">3. Registration Data and Privacy.</span><br/>
This Website is only available for members or employees of participating and active member-based organizations or corporate clients, respectively. If at any time we determine you are not a member or employee of a participating and active member-based organizations or corporate client, respectively, we may suspend or terminate your account without notice.] In order to access all of the services available through this Website, you will require an account and password that can be obtained by completing our online registration form, which requests certain information and data ("Registration Data"); provided, however, that if you access this Website through one of our Marketing Affiliate’s websites, if you have already logged in on the Marketing Affiliate’s website, you may not be required to have a separate account and password for this Website. By registering, you agree that all information provided in the Registration Data is true and accurate and that you will maintain and update this information as required in order to keep it current, complete and accurate.</p>
<p>The information we obtain through your use of this Website, including but not limited to your Registration Data, is subject to our Privacy Policy, which is specifically incorporated by reference into these Terms of Use.</p>

<p><span className="fw-bold">4. Payment of Fees.</span><br/>
If you purchase a product or service on this Website that requires payment of a fee, you agree to pay all such fees. For all charges for products or services on this Website, we will bill your credit or debit card, or other specific form of payment, as provided by you at the time of checkout. You agree to provide us with accurate and complete billing information, including valid credit or debit card information, your name, address and telephone number, and to provide us with any changes in such information.</p>
<p>If, for any reason, your credit or debit card company refuses to pay the amount billed for the product or service, you agree that we may, at our option, suspend, cancel, or terminate the product or service purchased.</p>
<p>In the event legal action is necessary to collect on balances due, you agree to reimburse us for all expenses incurred to recover sums due, including attorney fees and other legal expenses.</p>

<p><span className="fw-bold">5. Conduct on Website.</span><br/>
Your use of the Website is subject to all applicable laws and regulations, and you are solely responsible for the contents of your communications through the Website. By posting information in or otherwise submitting any form that may be available to you on or through this Website, you agree that you will not upload, share, post, or otherwise distribute or facilitate distribution of any content – including text, communications, software, images, sounds, data, or other information – that:<br/>
(a) Is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy, tortious, contains explicit or graphic descriptions or accounts of sexual acts (including but not limited to sexual language of a violent or threatening nature directed at another individual or group of individuals), or otherwise violates our rules or policies;
<br/>(b) Victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability;
<br/>(c) Infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right of any party;
<br/>(d) Constitutes unauthorized or unsolicited advertising, junk or bulk e-mail (also known as "spamming"), chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling;
<br/>(e) Contains software viruses or any other computer code, files, or programs that are designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of any third party; or
<br/>(f) Impersonates any person or entity, including any of our employees or representatives.</p>
<p>Furthermore, you agree not to use any robot, spider, scraper or other automated means to access the website for any purpose without our express written consent; and not to take any action that imposes (to be determined in our sole discretion) an unreasonable or disproportionally large load on our infrastructure, or that otherwise interferes with the functionality of the Website.</p>
<p>We neither endorse nor assume any liability or responsibility for the contents of any material uploaded or submitted by third-party users of the Website. We generally do not pre-screen, monitor, or edit the content submitted by users on or through this Website. However, we and our agents have the right in our sole discretion to remove any content that, in our judgment, does not comply with these Terms of Use and any other rules of user conduct for our Website, or is otherwise harmful, objectionable, or inaccurate. We are not responsible for any failure or delay in removing such content. You hereby consent to such removal and waive any claim against us arising out of such removal. See the section titled "User Materials" below for a description of the procedures to be followed in the event that any party believes that content posted on this Website infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right of any person or entity.</p>
<p>In addition, you may not use your account to breach security of another account or attempt to gain unauthorized access to another network or server. Not all areas of the Website may be available to you or other authorized users of the Website. You shall not interfere with anyone else's use and enjoyment of the Website or other similar services. Users who violate systems or network security may incur criminal or civil liability.</p>
<p>You acknowledge and agree that we may at any time, and at our sole discretion, terminate your account without prior notice to you for violating any of the above provisions. In addition, you acknowledge that we will cooperate fully with investigations of violations of systems or network security at other websites, including cooperating with law enforcement authorities in investigating suspected criminal violations.</p>

<p><span className="fw-bold">6. Third Party Content.</span><br/>
This Website may link you to other websites on the Internet or otherwise include references to information, documents, software, materials and/or services provided by other persons or entities (collectively, “Third-Party Content”). Third-Party Content may contain information or material that some people may find inappropriate or offensive. Third-Party Content and their owners or operators are not under our control, and you acknowledge that we are not responsible for the accuracy, copyright compliance, legality, decency, or any other aspect of the Third-Party Content, nor are we responsible for errors or omissions in any references to other parties or their products and services. The inclusion of such a link or reference is provided merely as a convenience and does not imply endorsement of, or association with, the Third-Party Content by us, or any warranty of any kind, either express or implied.</p>

<p><span className="fw-bold">7. Intellectual Property Information.</span><br/>
For purposes of these Terms of Use, "content" is defined as any information, communications, software, photos, video, graphics, music, sounds, and other material that can be viewed by users on our Website.</p>
<p>By accepting these Terms of Use, you acknowledge and agree that all un-disclaimed content presented to you on this Website is protected by copyrights, trademarks, service marks, patents or other proprietary rights and laws, and is our sole property or the property of our Related Companies. You are only permitted to use the content as expressly authorized by us or the specific content provider. Except for a copies made for your personal viewing and reference use only, you may not copy, reproduce, modify, republish, upload, post, transmit, or distribute any documents or information from this Website in any form or by any means, commercial or otherwise, without prior written permission from us or the specific content provider, and you are solely responsible for obtaining permission before reusing any copyrighted material that is available on this Website. Any unauthorized use of the materials appearing on this Website may violate copyright, trademark and other applicable laws and could result in criminal or civil penalties.</p>
<p>Neither we nor our Related Companies represent or warrant that your use of materials displayed on, or obtained through, this Website will not infringe the rights of third parties. See the section titled "User Materials" below for a description of the procedures to be followed in the event you believe that content posted on this Website infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right of any person or entity.</p>
<p>Unless otherwise specified elsewhere on the Website, all custom graphics, icons, logos and service names are trademarks, trademarks or service marks belong to us or our Related Companies. All other trademarks or service marks are property of their respective owners. Nothing in these Terms of Use grants you any right to use any trademark, service mark, logo, and/or the name of us or our Related Companies.</p>

<p><span className="fw-bold">8. User's Materials.</span><br/>
Subject to our Privacy Policy, any communication or material that you transmit to this Website or to us, whether by electronic mail or other means, for any reason, will be treated as non-confidential and non-proprietary. Except to the extent prohibited by applicable laws, and subject to our Privacy Policy, you grant us and our designated licensees a non-exclusive, paid-up, perpetual, and worldwide right to copy, distribute, display, perform, publish, translate, adapt, modify, and otherwise use such material for any purpose regardless of the form or medium (now known or not currently known) in which it is used.</p>
<p>Please do not submit confidential or proprietary information to us unless we have mutually agreed in writing otherwise to protect such information. We are also unable to accept your unsolicited ideas or proposals, so please do not submit them to us in any circumstance.</p>
<p>We respect the intellectual property of others, and we ask you to do the same. If you or any user of this Website believes its copyright, trademark or other property rights have been infringed by a posting on this Website, you or the user should send notification to our Designated Agent (as identified below) immediately. To be effective, the notification must include:<br/>
(a) A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
<br/>(b) Identification of the copyrighted work claimed to have been infringed;
<br/>(c) Information reasonably sufficient to permit us to contact the complaining party, such as address, telephone number and, if available, an electronic mail address at which the complaining party may be contacted;
<br/>(d) Identification of the material that is claimed to be infringing or to be subject to infringing activity and that is to be removed and information reasonably sufficient to permit us to locate the materials;
<br/>(e) A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, agent, or the law; and
<br/>(f) A statement that the information in the notification is accurate and, under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringing.</p>
<p>Pursuant to the Digital Millennium Copyright Act, 17 U.S.C. Sec. 512(c), our Designated Agent for Notice of claims of copyright infringement can be reached as indicated below. Service of repeat infringers of copyright or of users about whom repeat claims of copyright infringement are received may be terminated.</p>

<p className="fs-italic">For EnjoyTheTown.com:</p>
<p>Designated Agent for Claimed Copyright Infringement:
EnjoyTheTown.com<br/>
Attn: Copyright and Compliance, Legal Department<br/>
92 E. Main St.<br/>
Suite 405<br/>
Somerville, NJ 08876<br/>
You may also contact us by E-mail at:<br/>
contact@enjoythetown.com<br/>
</p>

<p className="fs-italic">For MemberDeals.com:</p>
<p>Designated Agent for Claimed Copyright Infringement:
Member Deals<br/>
Attn: Copyright and Compliance, Legal Department<br/>
5551 Vanguard Street<br/>
Orlando, FL 32819<br/>
You may also contact us by E-mail at:<br/>
copyrightissues@memberdeals.com</p>

<p>You acknowledge and agree that upon receipt of a notice of a claim of copyright infringement, we may immediately remove the identified materials from our Website without liability to you or any other person or entity and that the claims of the complaining party and the party that originally posted the materials may be referred to the United States Copyright Office for adjudication as provided in the Digital Millennium Copyright Act.</p>

<p><span className="fw-bold">9. Hotel-Specific Terms & Conditions.</span><br/>
Our hotel rates are only available through our Website and Customer Service, and cannot be obtained by calling the hotel directly. We are able to offer special hotel rates in many cases because our program is a private, member-based program. As such, all hotel reservations made through us, including Exclusive and Special Rates, may not be used to challenge a hotel’s Best Rate Guarantee, and are excluded from any such claims.</p>

<p><span className="fw-bold">10. Shopping Deals-Specific Terms & Conditions.</span><br/>
A number of third-party shopping partners are accessible through the Shopping Deals page of the Website (“Shopping Partners”). Shopping Partners, and their respective websites, products and/or services are not under our ownership, operation or control. Shopping Partners operate their respective websites and businesses subject to different security protocols, privacy policies and terms and conditions than the ones found on this website. Links to Shopping Partners are provided solely as a convenience to you, and do not constitute an endorsement by us of the content of their websites nor of their business practices. We are not responsible for examining or evaluating, and we do not warrant or claim responsibility in any way for, the products, services, offerings or business practices of any of these businesses, or the content of their websites. You should carefully review each Shopping Partner’s privacy statements and other conditions of use or sale.</p>
<p>All products and services purchased through a Shopping Partner will be fulfilled directly by the Shopping Partner. We will not be responsible to you for the products or services purchased through the Shopping Partner, nor the billing, credit card processing, fulfillment or customer service therefore. Purchases through any Shopping Partner may not be combined with any order made on this Website, and you may not use any gift or reward card or certificate branded for this Website towards any purchase made on a Shopping Partner’s website.</p>
<p>Offers are subject to change by the Shopping Partner without notice, and we will not be responsible for any out-of-date or erroneous advertisements.</p>

<p><span className="fw-bold">11. Disclaimers.</span><br/>
ALL MATERIALS AND SERVICES ON THIS WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT (a) THE SERVICES AND MATERIALS WILL MEET YOUR REQUIREMENTS, (b) THE SERVICES AND MATERIALS WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (c) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES OR MATERIALS WILL BE EFFECTIVE, ACCURATE OR RELIABLE, OR (d) THE QUALITY OF ANY PRODUCTS, SERVICES, OR INFORMATION PURCHASED OR OBTAINED BY YOU FROM THE WEBSITE FROM US OR OUR RELATED COMPANIES WILL MEET YOUR EXPECTATIONS OR BE FREE FROM MISTAKES, ERRORS OR DEFECTS.</p>
<p>THIS WEBSITE COULD INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES OR TYPOGRAPHICAL ERRORS. WE MAY MAKE CHANGES TO THE MATERIALS AND SERVICES AT THIS WEBSITE, INCLUDING THE PRICES AND DESCRIPTIONS OF ANY PRODUCTS LISTED HEREIN, AT ANY TIME WITHOUT NOTICE. THE MATERIALS OR SERVICES AT THIS WEBSITE MAY BE OUT OF DATE, AND WE MAKE NO COMMITMENT TO UPDATE SUCH MATERIALS OR SERVICES.
<p>THE USE OF THE SERVICES OR THE DOWNLOADING OR OTHER ACQUISITION OF ANY MATERIALS THROUGH THIS WEBSITE IS DONE AT YOUR OWN DISCRETION AND RISK AND WITH YOUR AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH ACTIVITIES.</p>
<p>Before purchasing products and services on or through this Website, review our Terms and Conditions of Sale, which are incorporated by reference into these Terms of Use. Additional terms and conditions may be applicable to you and any use of the Website or any purchase you make through the Website, and such terms and conditions will be posted on the Website where applicable.</p>
<p>Content available through this Website often represents the opinions and judgments of an information provider, Website user, or other person or entity not connected with us. We do not endorse, nor are we responsible for the accuracy or reliability of, any opinion, advice, or statement made by anyone other than an authorized spokesperson speaking in his/her official capacity.</p>
<p>SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>

<p><span className="fw-bold">12. Limitation of Liability.</span><br/>
Except as may be set forth in the Terms and Conditions of Sale, your exclusive remedy and our entire liability, if any, for any claims arising out of your use of this Website shall be a maximum of $40.00.</p>
<p>IN NO EVENT SHALL WE OR OUR RELATED COMPANIES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND FOR ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OF THIS WEBSITE OR OF ANY WEB SITE REFERENCED OR LINKED TO FROM THIS WEBSITE.</p>
<p>FURTHER, WE SHALL NOT BE LIABLE IN ANY WAY FOR THIRD PARTY GOODS OR SERVICES OFFERED THROUGH THIS WEBSITE OR FOR ASSISTANCE IN CONDUCTING COMMERCIAL TRANSACTIONS THROUGH THIS WEBSITE, INCLUDING WITHOUT LIMITATION THE PROCESSING OF ORDERS.</p>
<p></p>SOME JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>

<p><span className="fw-bold">13. Indemnification.</span><br/>
Upon a request by us, you agree to defend, indemnify, and hold us and our Related Companies, and their respective owners, officers, directors, employees, agents, representatives, attorneys and insurers harmless from all liabilities, claims, and expenses, including attorney's fees, that arise from your use or misuse of this Website. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any available defenses.</p>

<p><span className="fw-bold">14. Participation in Promotions.</span><br/>
From time-to-time, this Website may include promotions offered or fulfilled by third parties. In the event that you enter into correspondence with or participate in promotions of the third parties through this Website, any such correspondence or promotions, including the delivery of and the payment for goods and services, and any other terms, conditions, warranties or representations associated with such correspondence or promotions, are solely between you and the third party. We assume no liability, obligation or responsibility for any part of any such correspondence or promotion.</p>

<p><span className="fw-bold">15. Security and Password.</span><br/>
You are responsible for maintaining the confidentiality of your password and account and for any and all statements made and acts or omissions that occur through the use of your password and account, including any purchases made or charges incurred. Therefore, you must take steps to ensure that others do not gain access to your password and account. Our personnel will never ask you for your password. You may not transfer or share your account with anyone, and we reserve the right to immediately terminate your account in the event of any unauthorized transfer or sharing thereof.</p>

<p><span className="fw-bold">16. Export Controls.</span><br/>
This Website is subject to United States Export Controls. No software from this Website may be downloaded or exported (a) into (or to a resident of) Cuba, Iraq, Libya, North Korea, Iran, Syria, or any other country which the United States has embargoed goods; or (b) anyone on the United States Treasury Department's list of Specially Designated Nationals or the United States Commerce Department's Table of Deny Orders. By using this Website, you represent and warrant that you are not located in, under the control of, or a national or resident of any such country or on any such list.</p>

<p><span className="fw-bold">17. International Use.</span><br/>
Although this Website may be accessible worldwide, we make no representation that materials on this Website are appropriate or available for use in locations outside the United States, and accessing them from territories where their contents are illegal is prohibited. Those who choose to access this Website from other locations do so on their own initiative and are responsible for compliance with local laws. Any offer for any product, service, and/or information made in connection with this Website is void where prohibited.</p>

<p><span className="fw-bold">18. Termination of Use.</span><br/>
You agree that we may, in our sole discretion, terminate or suspend your access to all or part of the Website with or without notice and for any reason, including, without limitation, breach of these Terms of Use. Any suspected fraudulent, abusive or illegal activity may be grounds for terminating your relationship and may be referred to appropriate law enforcement authorities.</p>
<p>Upon termination or suspension, regardless of the reasons therefore, your right to use the services available on this Website will immediately cease, and you acknowledge and agree that we may immediately deactivate or delete your account and all related information and files in your account, and/or bar any further access to such files or this Website. We shall not be liable to you or any third party for any claims or damages arising out of any termination or suspension or any other actions taken by us in connection therewith. Sections 1, 3, 4-13, 18-23 of these Terms of Use, as well as your liability for any unpaid fees, shall survive any termination.</p>

<p><span className="fw-bold">19. Governing Law & Venue; Statute of Limitations.</span><br/>
For EnjoyTheTown.com Website: <br/>
The terms of the Agreement and any dispute relating thereto or between you and us will be governed by the laws of the State of New Jersey, without regard to conflict/choice of law principles. The United Nations Convention on Contracts for the International Sale of Goods does not apply to the Agreement. You agree to submit to the exclusive jurisdiction of the state and federal courts located in Somerset County, New Jersey, or another location designated by us. Any claim against us will be adjudicated on an individual basis and will not be consolidated in any proceeding with any claim or controversy of any other party. </p>
<p>Any cause of action brought by you against us or our Related Entities must be instituted within one year after the cause of action arises or be deemed forever waived and barred.</p>
<p>We make no representation that the products and services available through our Website are appropriate or available for use in locations outside of the United States, and accessing them from territories where such products and services are illegal is prohibited. Those who choose to access this Website from other locations do so on their own initiative and are responsible for compliance with local laws.</p>

<p>For MemberDeals.com Website:<br/>
This Website (excluding linked web sites) is controlled by us from our offices within the State of Florida, United States of America. It can be accessed from all fifty (50) States, as well as from other countries around the world. As each of these places has laws that may differ from those of Florida, by accessing this site both of us agree that the statutes and laws of the State of Florida will apply to all matters relating to the use of this site and the purchase of products and services available through this site, without regard to the conflicts of laws principles thereof and the United Nations Convention on the International Sales of Goods. Each of us agrees and hereby submits to the exclusive personal jurisdiction and venue of the Florida and federal courts in Miami-Dade County, Florida for the resolution of any dispute arising from your use of the Website. Each Party agrees to waive any objection s/he or it may have to Miami-Dade County, Florida serving as the venue for resolving any dispute between them arising from your use of the Website.</p>
<p>Any cause of action brought by you against us or our Related Entities must be instituted within one year after the cause of action arises or be deemed forever waived and barred.</p>
<p>We make no representation that the products and services available through our Website are appropriate or available for use in locations outside of the United States, and accessing them from territories where such products and services are illegal is prohibited. Those who choose to access this Website from other locations do so on their own initiative and are responsible for compliance with local laws.</p>

<p><span className="fw-bold">20. Minors.</span><br/>
Persons under the age of eighteen (18) are not eligible to purchase, cancel or modify any services available through this Website.</p>

<p><span className="fw-bold">21. Notices.</span><br/>
All notices to a party shall be in writing and shall be made via conventional mail. </p>

<p>Notices for EnjoyTheTown.com must be sent to EnjoyTheTown.com, Attn: Legal Department. 92 E. Main St. Suite 405 Somerville, NJ. 08876.</p>

<p>Notices for MemberDeals.com must be sent to Member Deals, Attn: Legal Department, 5551 Vanguard Street, Orlando, FL 32819. </p>

<p>Notices to you may be sent either to the e-mail address supplied for your account or to the address supplied by you as part of your Registration Data. In addition, we may broadcast notices or messages through the Website to inform you of changes to the Website or other matters of importance, and such broadcasts shall constitute notice to you.</p>
<p>Any notices or communication under these Terms of Use will be deemed delivered to the party receiving such communication (a) on the delivery date if delivered personally to the party; (b) two business days after deposit with a commercial overnight carrier, with written verification of receipt; or (c) five business days after the mailing date, if sent by US mail, return receipt requested.</p>

<p><span className="fw-bold">22. Entire Agreement.</span><br/>
These terms and conditions constitute the entire agreement and understanding between us concerning the subject matter hereof and supersedes all prior agreements and understandings of the parties with respect thereto. These Terms of Use may NOT be altered, supplemented, or amended by the use of any other document(s). Any attempt to alter, supplement or amend this document or to enter an order for products or services which are subject to additional or altered terms and conditions shall be null and void, unless otherwise agreed to in a written agreement signed by you and us. To the extent that anything in or associated with this Website is in conflict or inconsistent with these Terms of Use, these Terms of Use shall take precedence.</p>

<p><span className="fw-bold">23. Miscellaneous.</span><br/>
You may not assign your rights and obligations under these Terms of Use to any party, and any purported attempt to do so shall be null and void. We may freely assign our rights and obligations under these Terms of Use.</p>
<p>You agree not to sell, resell, reproduce, duplicate, copy, or use for any commercial purposes any portion of this Website, or use of or access to this Website.</p>
<p>In addition to any excuse provided by applicable law, we shall be excused from liability for non-delivery or delay in delivery of products and services available through our Website arising from any event beyond our reasonable control, whether or not foreseeable by either party, including but not limited to, labor disturbance, war, fire, accident, adverse weather, inability to secure transportation, governmental act or regulation, and other causes or events beyond our reasonable control, whether or not similar to those which are enumerated above.</p>
<p>If any part of these Terms of Use is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect.</p>
<p>Any failure by us to enforce or exercise any provision of these Terms of Use or related rights shall not constitute a waiver of that right or provision.</p>

<p><span className="fw-bold">24. Travel Service Disclosures.</span><br/>
For Florida Residents:<br/>
Member Deals is registered with the State of Florida as a Seller of Travel. Registration No. ST-37777.</p>

<p><span className="fw-bold">25. Contact Information.</span><br/>
Except as explicitly noted on this Website, the services available through this Website are offered by Member Deals, located at 5551 Vanguard Street, Orlando, FL 32819.</p>



</div>

  );

}

export { TermsAndConditionsUse }