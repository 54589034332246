import React from 'react'
import { Modal } from 'react-bootstrap';
import { IHotelModal, ITicketModal } from '_interfaces';


const ModalSubscriptionRequired: React.FunctionComponent<{item: ITicketModal | IHotelModal, onHide: any}> = ({item, onHide, ...props}) => {

  const onClickSubscribe = ()=> {
    window.location.href = '/subscription';
  }

  return (

    <Modal
      show={item.show}
      onHide={onHide}
      animation={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Subscription Required</Modal.Title>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onHide}></button>
      </Modal.Header>
      <Modal.Body>Save on this deal now as well as an additional $1,500.00 on other great deals!
        <div>
          <button type="button" className="btn btn-primary mt-2" onClick={onClickSubscribe}>Subscribe</button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export { ModalSubscriptionRequired }