import React from 'react';

const Blurb: React.FunctionComponent<{handleShow: any, children: any, text: string, arrowDirection: "left" | "right"}> = ({handleShow, children, text, arrowDirection}) => {
  return (
    <div className={`blurb border-${arrowDirection === "left" ? "end" : "start"} border-5 border-primary px-3`}>
      {children}
      <div className="see-latest" onClick={handleShow}>{arrowDirection === 'left' ? '<' : ''} {text} {arrowDirection === 'right' ? '>' : ''}</div>
    </div>
  );
}

export { Blurb }