import React, { useEffect, useState } from 'react';
import { IHotel, ISectionProps, ISubscriber, IHotelModal } from '_interfaces';
import { Stars, SectionAllLink } from '..';
import { ModalSubscriptionRequired } from '_components/_modals';
import { Section } from '_constants'
import { dataService } from '_services';
const HotelBox: React.FunctionComponent<{ hotel: IHotel, account: ISubscriber | null, onHotelClick: any }> = ({ hotel, account, onHotelClick }) => {
  return (
    <>
      <div className="col-md-6" onClick={(e) => { onHotelClick(e, hotel) }}>
        <div className="ticket-container m-3">
          <h5>Major Travel Sites</h5>
          <div><img className="img-fluid" src={hotel.thumbnail} alt={hotel.title} /></div>
          <div className="blurb-overlay text-center">
            <div className="location">{account === null ? hotel.city : hotel.altTitle}</div>
            <div className="effective-dates fw-bold text-white">{hotel.startDate} - {hotel.endDate}</div>
            <Stars count={hotel.stars} />
          </div>
          <Pricing discounted={false} hotel={hotel} />
        </div>
      </div>
      <div className="col-md-6" onClick={(e) => { onHotelClick(e, hotel) }}>
        <div className="ticket-container m-3">
          <h5>EnjoyTheTown Members</h5>
          <div><img className="img-fluid" src={hotel.thumbnail} alt={hotel.title} /></div>
          <div className="blurb-overlay text-center">
            <div className="location">{account === null ? hotel.city : hotel.altTitle}</div>
            <div className="effective-dates fw-bold text-white">{hotel.startDate} - {hotel.endDate}</div>
            <Stars count={hotel.stars} />
          </div>
          <Pricing discounted={true} hotel={hotel} />
        </div>
      </div>
    </>
  );
}

const Pricing: React.FunctionComponent<{ hotel: IHotel, discounted: boolean }> = ({ hotel, discounted }) => {
  return (
    <div className={`pricing hotel ${discounted ? 'discounted' : 'regular'}`}>
      {discounted ? (
        <span className="percent-off">${hotel.discountPrice}<sup>*</sup></span>)
        : (
          <span className="percent-off">${hotel.regularPrice}</span>
        )}
      <span className="small">per night</span>
    </div>
  );
}


const HotelsAndVillas: React.FunctionComponent<ISectionProps> = ({ handleShow, account }) => {

  const [hotel, setHotel] = useState<IHotel>({} as IHotel);
  const [item, setItem] = useState<IHotelModal>({} as IHotelModal);

  const loggedIn = account !== null;

  const Blurb = () => {
    return (
      <div className="blurb">
        <h4 className="p-1 my-1">Hotels and Luxury Villas</h4>
        <div className="savings bg-tertiary p-2 rounded fw-bold">Save $20 to $100 per night</div>
        <div className="my-1 px-2 fw-bold see-latest" onClick={handleShow}>See Latest Hotel Deals &gt;</div>
      </div>
    );
  }

  const onHotelClick = (e: any, hotel: IHotel) => {
    if (loggedIn) {
      if (account?.activeSubscription) {
        window.location.href = hotel.dealUrl ? hotel.dealUrl : '/';
      } else {
        setItem({ hotel: hotel, show: true });
      }
    } else {
      handleShow(e);
    }
  }

  const handleCloseDialog = () => {
    setItem({ hotel: {} as IHotel, show: false });
  }

  const onCityClick = (e: any, hotel: IHotel) => {
    if (loggedIn) {
      setHotel(hotel);
    } else {
      handleShow(e);
    }
  }

  const onDefaultLink = () => {
    setItem({ hotel: {} as IHotel, show: true })
  }

  const hotels: IHotel[] = dataService.getHotels();

  useEffect(() => {
    setHotel(hotels[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section id="hotel-and-villas" className={`${loggedIn ? 'logged-in' : ''}`}>

      <ModalSubscriptionRequired item={item} onHide={handleCloseDialog} />

      <div className="container pb-3">


        {loggedIn &&
          <SectionAllLink
            mainTitle='Hotels and Luxury Villas'
            linkTitle='View All Hotel Deals &gt;'
            onClick={onDefaultLink}
            section={Section.Hotels}
            account={account}
          />

        }

        <div className="row justify-content-md-center">
          {!loggedIn &&
            <div className="col-md-3 mt-3">
              <Blurb />
            </div>
          }

          <div className="col-md-9">
            <div className="container">
              <div className="row">
                  <HotelBox hotel={hotel} onHotelClick={onHotelClick} account={account} />
              </div>

              <div className="container">
                <div className="row justify-content-center">
                  {hotels && hotels.length > 0 ? (
                    hotels.map((hotel, i: number) =>
                      <div className="col-md-auto" key={`hotel-key-${i}`}>
                        <button 
                          className={`btn btn-${loggedIn ? 'lightblue' : 'primary'} btn-auto m-1 btn-city input-block-level form-control`}
                          type="button"
                          onClick={(e) => { onCityClick(e, hotel) }}>{hotel.city}</button>
                      </div>

                    )) : (
                    <div>No Data</div>
                  )}
                </div>

                <div className="row justify-content-center">
                  <div className="col text-center small mt-2">
                    * Deal prices are as of July 6th, 2021. Prices are subject to change without notice.
                  </div>
                </div>

              </div>

            </div>


          </div>

        </div>


      </div>
    </section>
  );
}

export { HotelsAndVillas }