import React, { useState } from 'react';
import { accountService } from '_services';
import { ICouponDiscount, IOffer } from '_interfaces';
import { FieldWithError } from '_common';
import { Coin } from 'react-bootstrap-icons';

const CouponForm: React.FunctionComponent<{ errors: any, touched: any, values: any, updateCharge: any, offer: IOffer }> = ({ errors, touched, values, updateCharge, offer }) => {
  const [status, setStatus] = useState<string>('');
  const onApply = async () => {
    try {
      const info: ICouponDiscount = await accountService.getCouponInfo(values.couponCode);
      updateCharge(offer.cost ? offer.cost - (offer.cost * info.discountPct / 100) : 0);
      setStatus('Discount Applied');
    } catch (error) {
      setStatus(error.message);
    }
  }

  return (
    <div className="card">
      <h4 className="card-header">Discount Code</h4>
      <div className="card-body">
        <div className="row">
          <div className="col-md-9">
            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName='couponCode'
              placeholder='Discount Code'
              icon={(<Coin />)}
            />

          </div>
          <div className="col-md-3">
            <button type="button" onClick={onApply} className="btn btn-primary">Apply Code</button>

          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
          {status && <div>{status}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export { CouponForm }