import React, { useState } from 'react';
import { ITicket, ISectionProps, ITicketModal, ISubscriber } from '_interfaces';
import { ModalSubscriptionRequired } from '_components/_modals';
import { Section } from '_constants'
import { SectionAllLink } from '..';
import { dataService } from '_services';
import { Blurb } from '_common';

const Pricing: React.FunctionComponent<{ ticket: ITicket, discounted: boolean }> = ({ ticket, discounted }) => {
  return (
    <div className={`pricing hotel ${discounted ? 'discounted' : 'regular'}`}>
      <span className="percent-off">${discounted ? ticket.discountPrice : ticket.regularPrice}</span>
      <span className="small">per day</span>
    </div>
  );
}

interface IDualBoxProps {
  ticket: ITicket,
  account?: ISubscriber | null,
  onTicketClick?: any,
}
const DualBox: React.FunctionComponent<IDualBoxProps> = ({ ticket, account, onTicketClick }) => {
  return (
    <>
      <div className="col-md-4 mx-3" onClick={(e) => { onTicketClick(e, ticket) }}>
        <div className="themepark">
          <div className={`ticket-container ${account ? 'logged-in' : ''}`}>
            <h5>{ticket.title}</h5>
            <div className="ticket-poster">
              <img className="img-fluid" src={ticket.thumbnail} alt={ticket.title} />
              <div className="ticket-overlay-container">
                <div className="ticket-overlay-title">{ticket.overlayText?.title}</div>
              </div>

            </div>
            <Pricing ticket={ticket} discounted={false} />
          </div>
        </div>
      </div>

      <div className="col-md-4 mx-3" onClick={(e) => { onTicketClick(e, ticket) }}>
        <div className="themepark">
          <div className={`ticket-container ${account ? 'logged-in' : ''}`}>
            <h5>{ticket.altTitle}</h5>
            <div className="ticket-poster"><img className="img-fluid" src={ticket.thumbnail} alt={ticket.title} /></div>
            <Pricing ticket={ticket} discounted={true} />
            <div className="ticket-overlay-container">
              <div className="ticket-overlay-title">{ticket.overlayText?.title}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const Box: React.FunctionComponent<{ ticket: ITicket }> = ({ ticket }) => {
  return (
    <div className="my-3 mx-3">
      <div className="ticket-container">
        <div className="ticket-poster"><img width="100%" src={ticket.thumbnail} alt={ticket.title} /></div>
        <div className="pricing">
          <span className="percent-off">{ticket.percentOff}% <br />off</span>
        </div>
      </div>
    </div>
  );
}

const ThemeParks: React.FunctionComponent<ISectionProps> = ({ handleShow, account }) => {
  const [item, setItem] = useState<ITicketModal>({} as ITicketModal)

  const tickets: ITicket[] = dataService.getThemeParks();

  const loggedInTicket: ITicket = dataService.getThemeParkLoggedIn();

  const loggedIn = account !== null;

  const handleShowTicket = (ticket: ITicket) => {
    setItem({ ticket: ticket, show: true })
  };

  const handleCloseTicket = () => {
    setItem({ ticket: {} as ITicket, show: false });
  }

  const onTicketClick = (e: any, ticket: ITicket) => {
    if (loggedIn) {
      if (account?.activeSubscription) {
        window.location.href = ticket.dealUrl ? ticket.dealUrl : '/';
      } else {
        handleShowTicket(ticket)
      }
    } else {
      handleShow(e);
    }
  }

  return (
    <section id="theme-parks-section">

      <ModalSubscriptionRequired item={item} onHide={handleCloseTicket} />

      <div className="container">

        {loggedIn &&
          <div className="row m-2">
            <SectionAllLink
              mainTitle='Theme Park Tickets'
              linkTitle='View All Theme Park Deals &gt;'
              onClick={() => setItem({ ticket: {} as ITicket, show: true })}
              section={Section.ThemeParks}
              account={account}
            />
          </div>
        }
        <div className="row justify-content-md-center">


        {!loggedIn &&
            <div className="col-md text-end d-flex align-items-center order-md-1 mb-3">
              <Blurb handleShow={handleShow} text="See Latest Theme Park Deals" arrowDirection="left">
                <h4>Theme Parks</h4>
                <span className="fw-bold">Save $30 per person at Disney</span>, Universal and Six Flags:
                <span className="fw-bold">Save an average of $10 per person<br />
                  a day. $120 per year for a family of 4</span>
              </Blurb>
            </div>
          }

          {!loggedIn && tickets && tickets.length > 0 ? (
            tickets.map((ticket: ITicket, i: number) =>
              <div key={`ticket-${i}`} className="col-md-3 text-center order-md-12" onClick={(e) => { onTicketClick(e, ticket) }}>
                <Box ticket={ticket} />
              </div>
            )
          ) : (
            <DualBox ticket={loggedInTicket} account={account} onTicketClick={onTicketClick} />
          )}




        </div>
      </div>
    </section>
  );

}

export { ThemeParks }