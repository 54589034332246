import React from 'react';

const MenuLabel = (props: any) => {
  const markup = {
    __html: props.text
  };

  return (
    <div dangerouslySetInnerHTML={markup}></div>
  );
}

export { MenuLabel }