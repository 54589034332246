import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FieldWithError } from '_common';
import { PersonCircle } from 'react-bootstrap-icons';
import { accountService } from '_services';

const ForgotPassword = () => {

  const initialValues = {
    emailAddress: ''
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('username/email address is required')
  })

  const onSubmit = async (data: any, actions: any) => {
    const { setStatus, setSubmitting } = actions;
    try {
      await accountService.requestResetPassword(data.username);
      setStatus('Please check your email in a few minutes for instructions to reset your password.')
    } catch (err: any) {
      setStatus(err.message);
      setSubmitting(false);
    }
  }

  return (
    <article>
    <div className="container mt-5">
      <h3>Forgot Password</h3>
      <div className="row">
        <div className="col">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ setSubmitting, errors, touched, values, status, isSubmitting }) => {
              return (
              <Form>
                <div className="row">
                  <div className="col-md-6 mb-3">

                    <FieldWithError
                      errors={errors}
                      touched={touched}
                      fieldName='username'
                      placeholder='Enter User Name'
                      icon={(<PersonCircle />)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                  {status &&
                    <div className="alert alert-primary">{status}</div>
                  }
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <button disabled={isSubmitting} className="btn btn-primary" type="submit">Submit</button>
                  </div>
                </div>
              </Form>
              );
            }}
          </Formik>
        </div>
      </div>

    </div>
    </article>
  );
}

export { ForgotPassword }