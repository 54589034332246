import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { ModalEmailRegistration } from '_components/_modals';


const Pricing:React.FunctionComponent = () => {
  return(
    <div className="home-pricing">
      Subscribe for <del>$8</del> $5/mo for a limited time!*<br/>
      <span style={{fontSize: '0.6em', fontWeight: 'normal'}}>* Billed at an annual rate</span>
    </div>    
  );
}


const Jumbotron: React.FunctionComponent<{showPrice?: boolean}> = ({showPrice}) => {
  const [showEmailForm, setShowEmailForm] = useState(false);
  const handleClose = () => setShowEmailForm(false);

  const onClick = () => {
    setShowEmailForm(true);
  }

  return (
    <section id="jumbotron">

      <ModalEmailRegistration
        show={showEmailForm}
        handleClose={handleClose}
      />

      <div className="d-lg-none d-xl-none d-xxl-none jumbotron">
        <div className="m-2 p-2">
          <div className="bg-primary rounded p-3">
            <h5 className="fw-bold text-white text-center">Unlimited Access to the Best Members-Only Exclusive Discounts</h5>
            <div className="col-sm-6 mx-auto">
              <div className="d-grid gap-2 justify-content-sm-center my-3">
                <button type="button" className="btn bg-white btn-sm text-black" onClick={onClick}>Register for Free to Browse Deals</button>
              </div>
              <div className="text-center">
                {showPrice && <Pricing/>}
                <HashLink
                  className="text-white"
                  scroll={(el: any) => el.scrollIntoView({ behavior: 'smooth' })}
                  to={'#how-it-works'}>
                  How It Works
                </HashLink>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="text-center jumbotron d-none d-lg-block">
        <div className="p-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-auto">
                <div className="bg-primary m-5 rounded main-box">
                  <h3 className="fw-bold text-white main-callout">Unlimited Access to the <br/>Best Members-Only Exclusive Discounts</h3>
                  <div className="d-grid gap-2 justify-content-sm-center my-3">
                    <button type="button" className="btn bg-white btn-lg px-4 gap-3 text-black register-for-free" onClick={onClick}>Register for Free to Browse Deals</button>
                  </div>
                  <div>
                    {showPrice && <Pricing/>}
                    <HashLink
                      className="text-white how-it-works"
                      scroll={(el: any) => el.scrollIntoView({ behavior: 'smooth' })}
                      to={'#how-it-works'}>
                      How It Works
                    </HashLink>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { Jumbotron }