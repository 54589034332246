import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  useStripe,
  useElements,
  CardNumberElement
} from '@stripe/react-stripe-js';

import { stripeService } from '_services';
import { StripeCheckoutForm } from '.';
import { ThankYouPage } from '_components/_billing';
import { IOffer } from '_interfaces';
import { CreditCardForm, CouponForm } from '_components/_forms';

//@TODO: this needs to be refactored; it's similar to _forms/SubscribeForm.tsx

const SubscriptionForm: React.FunctionComponent<{ offer: IOffer }> = ({ offer }) => {

  const stripe = useStripe();
  const elements = useElements();

  const initialFormState: any = {
    firstName: '',
    lastName: '',
    billingAddress1: '',
    billingAddress2: '',
    billingCity: '',
    billingState: '',
    billingZip: '',
    creditCardCode: '',
  };

  const [step, setStep] = useState<number>(0);
  const [status, setStatus] = useState<string>('');
  const [charge, setCharge] = useState<number>(offer.cost || 0);

  useEffect(() => {
    if (!offer.cost) {
      throw Error('No Offer');
    }
    setCharge(offer.cost || 0);
  }, [offer])

  const steps = [
    'Billing Info',
    'Review Order and Payment',
    'Complete Order'
  ];

  const submitCreditCard = async (data: any, actions: any) => {
    //@TODO: make this strict
    const billingDetails: any = {
      name: `${data.firstName} ${data.lastName}`,
      address: {
        city: data.billingCity,
        line1: data.billingAddress1,
        state: data.billingState,
        postal_code: data.billingZip
      }
    };

    const paymentIntent = await stripeService.getPaymentIntent();
    const cardNumberElement = elements ? elements.getElement(CardNumberElement) : null;

    const stripeClientSecret = paymentIntent.data.stripeClientSecret;
    if (cardNumberElement) {
      stripe?.confirmCardSetup(stripeClientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: billingDetails
        }
      })
        .then((result) => {
          if (result.error) {
            setStatus(`An error occurred: ${result.error.message}`);
            actions.setSubmitting(false);
          } else {
            stripe.retrieveSetupIntent(stripeClientSecret)
              .then((setupIntentResult) => {
                stripeService.pay({ setupIntentResult, coupon: { code: data.couponCode } })
                  .then((result) => {
                    // success. go to Order Complete page
                    nextStep();
                  })
                  .catch((err) => {
                    actions.setSubmitting(false);
                    console.error('error calling pay endpoint', err);
                    setStatus(`An error occurred: ${err}`);
                  });
              })
          }
        })
    }

  }

  const ConfirmationForm: React.FunctionComponent<{ values: any }> = ({ values }) => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="card my-2">
            <h4 className="card-header">Billing Info</h4>
              <div className="card-body">
                <div className="container">
                  <div className="row">
                    <div className="col-5">Name</div>
                    <div className="col-7">{values.lastName}, {values.firstName}</div>
                  </div>

                  <div className="row">
                    <div className="col-5">Billing Address</div>
                    <div className="col-7">
                      {values.billingAddress1}<br />
                      {values.billingAddress2 ? <>{values.billingAddress2}<br /></> : ''}
                      {values.billingCity}<br />
                      {values.billingState}<br />
                      {values.billingZip}</div>
                  </div>

                  <div className="row">
                    <div className="col-5">Amount</div>
                    <div className="col-7">${charge.toFixed(2)}</div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="col-md-6">
            <CreditCardForm />
          </div>
        </div>


        <div className="row justify-content-center text-center my-3">
          <div className="col alert-primary p-3">
            {charge === 0 ?
              <>Your subscription is FREE, but a credit card is required to purchase. You credit card will not be charged.<br /></>
              :
              <>When you confirm your payment, you will see a charge from "EnjoyTheTown" for <span className="price">${charge.toFixed(2)}</span><br /></>
            }
            This subscription automatically renews and charges your credit card annually. You may cancel at any time.
          </div>
        </div>

      </div>
    );
  }

  const MainCheckoutForm: React.FunctionComponent<{ errors: any, touched: any, values: any }> = ({ errors, touched, values }) => {
    return (<>
      <div className="row mb-2">
        <div className="col-md-6 mh-100">
          <div className="card h-100">
            <h4 className="card-header">Shopping Cart</h4>
            <div className="card-body py-3">
              <div className="row">
                <div className="col">
                  Description: {offer.name}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  Price: <span className="price">${charge.toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="col-md-6">
          <CouponForm errors={errors} touched={touched} updateCharge={updateCharge} values={values} offer={offer} />
        </div>
      </div>

      <div className="row justify-content-center">
        <StripeCheckoutForm errors={errors} touched={touched} updateCharge={updateCharge} values={values} offer={offer}></StripeCheckoutForm>
      </div>

    </>
    )
  }

  const FormButtons: React.FunctionComponent<{ isSubmitting: any }> = ({ isSubmitting }) => {
    return (
      <div className="row justify-content-center mt-5">
        <div className="col-md-6 text-center">
          {status && <div className="alert alert-danger">{status}</div>}

          {step === 0 && <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-lg">{steps[step + 1]}</button>}

          {step !== 0 &&
            <div className="container-fluid">
              <div className="row">
                <div className="col"><button disabled={isSubmitting} className="btn btn-secondary btn-lg btn-block" onClick={onBack}>{'Back - ' + (steps[step - 1])}</button></div>
                <div className="col"><button disabled={isSubmitting} className="btn btn-primary btn-lg btn-block" type="submit">{'Next - ' + (steps[step + 1])}</button></div>
              </div>
            </div>
          }
          {isSubmitting && <div className="spinner-border"></div>}
        </div>
      </div>);
  }

  const updateCharge = (charge: number) => {
    setCharge(charge);
  }

  const onSubmit = (data: any, actions: any) => {
    console.log('submit step', step);
    switch (step) {
      case 1: // confirmation submitted
        submitCreditCard(data, actions);
        break;
      default:
        if (actions.setSubmitting) {
          actions.setSubmitting(false);
        }
        nextStep();
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  }
  const onBack = () => {
    setStep(step - 1);
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is Required'),
    billingAddress1: Yup.string().required('Billing Address is required'),
    billingCity: Yup.string().required('Billing City is required'),
    billingState: Yup.string().required('Billing State is required'),
    billingZip: Yup.string().required('Billing Zip is required'),
  });

  const BreadCrumbs = () => {
    return (
      <div className="row justify-content-center top-banner">
        <div className="col">
          <div className="py-5 text-center">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center">
                {steps && steps.map((item, index) => <li key={index} className={'breadcrumb-item ' + (index === step ? "active" : "")}>
                  {item}
                </li>)}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container">
      <BreadCrumbs />

      {step === 2 ? (
        <ThankYouPage />
      ) : (
        <Formik
          initialValues={initialFormState}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, isSubmitting, errors, touched }) => {
            return (
              <Form>
                {step === 0 && <MainCheckoutForm errors={errors} touched={touched} values={values} />}
                {step === 1 && <ConfirmationForm values={values} />}
                <FormButtons isSubmitting={isSubmitting} />
              </Form>
            )
          }}
        </Formik>
      )}
    </div>
  );
}
//const StripeSubscriberSubscriptionForm = withRouter(SubscriptionForm);
export { SubscriptionForm as StripeSubscriberSubscriptionForm }