import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './custom.scss';
import './App.css';

import { Navigation, Footer } from '_common';
import { authenticationService, accountService } from '_services';
import {
  Main,
  NotFoundPage,
  //SubscriptionPage,
  AboutUs,
  ContactUs,
  TermsAndConditionsUse,
  TermsAndConditionsSale,
  PrivacyPolicy,
  ForgotPassword,
  ResetPassword,
  ConfirmEmail,
} from '_components';

import {
  StripeSubscription
} from '_components/_payments/Stripe';

import {
  Account
} from '_components/_account';

import { ISubscriber } from '_interfaces';
import { RegisterAndSubscribe } from '_components/_payments/Stripe/RegisterAndSubscribe';


const App = (props: any) => {

  const [account, setAccount] = useState<ISubscriber | null>({} as ISubscriber);

  useEffect(() => {
    const initAccount = async () => {
      try {
        const data = await accountService.getAccount();
        setAccount(data.account);
      } catch (err) {
        setAccount(null);
      }
    }
    initAccount();
  }, []);

  const login = () => {
  }

  const logout = () => {
    authenticationService.logOut();
    setAccount(null);
    window.location.href = '/';
  }

  const url = new URL(window.location.href);
  const page = url.searchParams.get('page');

  return (
    <div className="main-body">
      <div className="container">
        <header>
          <Navigation
            logout={logout}
            login={login}
            account={account}
            setAccount={setAccount}
            isLoggedIn={account !== null}
            modalPage={page} />
        </header>

        <Router>
          <Switch>
            {/*<Route path='/login'><LoginForm login={login}/></Route>*/}
            {/*<PrivateRoute path='/loggedin' roles={[Role.Registered]} component={LoggedInHomePage}/>*/}
            <Route exact path='/about-us' component={AboutUs} />
            <Route exact path='/terms-of-use' component={TermsAndConditionsUse} />
            <Route exact path='/terms-of-sale' component={TermsAndConditionsSale} />
            <Route exact path='/privacy-policy' component={PrivacyPolicy} />
            <Route exact path='/contact-us' component={ContactUs} />
            <Route exact path='/register-subscribe' render={(props) => <RegisterAndSubscribe account={account} {...props} />} ></Route>
            <Route exact path='/subscription'>{account !== null ? <StripeSubscription /> : <Redirect to="/" />}</Route>
            <Route exact path='/reset-password/:authID' component={ResetPassword} />
            <Route exact path='/forgot-password' component={ForgotPassword} />
            <Route exact path='/confirm-email/:authID' component={ConfirmEmail} />
            <Route exact path='/my-account' component={Account}></Route>
            <Route exact path='/b'><Main account={account} showPrice={true} /></Route>
            <Route exact path='/'><Main account={account} /></Route>
            <NotFoundPage />
          </Switch>
        </Router>

        <Footer />

      </div>
    </div>
  );
}

export default App;
