import { IConfig } from '../_interfaces';

export default {
  apiDomain: 'https://portal-api.accessiq.net',
  apiBasePath: '/v1/site',
  siteUID: 7781139412205647,
  tokenName: 'tk',
  aiqOfferId: 23,
  appDomain: 'https://www.enjoythetown.com'
} as IConfig
