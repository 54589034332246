import React from 'react';

const Transactions = (props: any) => {

  const { rows } = props;

  return (
    <React.Fragment>
      <div className="card mt-3">
        <h4 className="card-header">Transaction History</h4>
        <div className="card-body">
        If you have questions about your purchases, please visit our <a href="https://memberdeals.com/enjoythetown/contact.php">Customer Service</a> page for more information.
        </div>
      </div>
      <div className="card mt-3">
        <h4 className="card-header">Subscription Payments</h4>
        <div className="card-body">

          <table className="table table-hover">
            <thead>
              <tr>
                <th>Reference ID</th>
                <th>Amount</th>
                <th>Transaction Date</th>
              </tr>
            </thead>
            <tbody>
              {rows && rows.length > 0 ? (
                rows.map((row: any) =>
                  <tr key={row.id}>
                    <td>{row.referenceId}</td>
                    <td>${row.amount.toLocaleString(navigator.language, { maximumFractionDigits: 2 })}</td>
                    <td>{row.creationTimestamp}</td>
                  </tr>
                )) : (
                  <tr><td colSpan={3}>No Transactions</td></tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export { Transactions };
