import { IConfig } from '../_interfaces';

export default {
  apiDomain: 'https://portal-api.devex.accessiq.net',
  apiBasePath: '/v1/site',
  siteUID: 2782455004486873,
  tokenName: 'tk',
  aiqOfferId: 24,
  appDomain: 'https://localhost:3000'
} as IConfig
