import React from 'react';

const AboutUs = () => {
  return(
    <div className="container">
      <div className="row">
        <div className="col">
          <h3>About Us</h3>

        </div>
      </div>
    </div>
  );

}

export { AboutUs }