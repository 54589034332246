import React from 'react';

const ContactUs = () => {
  return (
    <div className="container-md">
      <div className="row justify-content-center m-5">
        <div className="col-lg">
          <h4>Contact Us</h4>

          If you have questions regarding your EnjoyTheTown subscription, please contact us via email or standard mail:
          <dl>
            <dt>Email</dt><dd><a href="mailto:support@enjoythetown.com">support@enjoythetown.com</a></dd>
            <dt>Address</dt><dd>
              92 East Main Street,<br/>
              Suite 405<br/>
              Somerville, NJ 08876<br/>
            </dd>
          </dl>

          <h4>Customer Service</h4>
          If you need assistance regarding your purchases or have questions regarding deals, please visit our <a href="https://memberdeals.com/enjoythetown/contact.php">Customer Service</a> page for more information.
        </div>
      </div>
    </div>
  );

}

export { ContactUs }