import axios from 'axios';
import { 
  ICredentials,
} from '../_interfaces';

import {
  config
} from '../config';

import { 
  accountService
} from '.';

class AuthenticationService {

  constructor(
    protected apiBaseUrl: string,
    protected tokenName: string,
  ) {

  }

  async login(credentials: ICredentials): Promise<any> {
    const url:string  = `${this.apiBaseUrl}/subscriber/login`;
    try {
      const response: any = await axios.post(url, credentials);
      if (!response.data || response.data.error) {
        throw Error(`${response.data.error.message}`)
      } 
      // get the token
      localStorage.setItem(this.tokenName, response.data.token);
      await this.setAccountFromToken(response.data.token);

      return response.data;
    }
    catch (err) {
      throw Error(`${err.message}`);
    }
  }

  async setAccountFromToken(token: string) {
      // get the account and save it
      try {
        const acct = await accountService.getAccount();
        localStorage.setItem('acct', JSON.stringify(acct.account));
      } catch (err) {
        console.error('error in setAccountFromToken', err);
        throw Error(`error setting account ${err.message}`);
      }
  }

  isAuthenticated(): boolean {
    // @TODO: need to secure this.
    return localStorage.getItem('acct') !== null;
  }

  getToken(): string {
    const token = localStorage.getItem(this.tokenName) || '';
    return token;
  }

  setToken(token: string): void {
    localStorage.setItem(this.tokenName, token);
  }

  getAuthHeader(): Object {
    const header = {headers: {
        Authorization: 'Bearer ' + this.getToken(),
        token: this.getToken()
      }};
    return header;
  }

  logOut() {
    localStorage.removeItem(this.tokenName);
    localStorage.removeItem('acct');
  }
}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/${config.siteUID}`;
const authenticationService = new AuthenticationService(apiBaseUrl, config.tokenName);
export { authenticationService }