import React from 'react';
import { ITestimonial } from '_interfaces';

const Testimonials = () => {

  const renderStars = (count: number) => {
    const items = [];
    for (let i = 0; i < count; i++) {
      items.push(<div className="star" key={`star-${i}`}>&nbsp;</div>);
    }
    return items;
  }

  const testimonials: ITestimonial[] = [
    {
      text: 'I saved over $1,300 on laptop computer purchases in 2020 by using discounts on the platform.',
      stars: 5
    },
    {
      text: 'The savings on my first deal paid for the subscription. Well worth it!',
      stars: 5
    },
    {
      text: 'Great customer service. Highly recommend it.',
      stars: 5
    },
  ]


  return (
    <section id="testimonials-section">
      <div className="container pb-3">
        <div className="row justify-content-center">
          <div className="col">
            <h4 className="text-primary text-center fw-bold">Testimonials</h4>
          </div>
        </div>
        <div className="row">
          {testimonials && testimonials.length > 0 ? (
            testimonials.map((testimonial: ITestimonial, i: number) => {
              return (
                <div className="col" key={`tmnl-${i}`}>
                  <div className="testimonial">"{testimonial.text}"
                    <div className="star-container mt-1 text-center">
                      {renderStars(testimonial.stars)}
                      <div className="clear"></div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>No Data</div>
          )}
        </div>
      </div>
    </section>
  );
}

export { Testimonials }