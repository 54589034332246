import React, { useState } from 'react';
import { ProfileForm } from '_components';
import { ISubscriber } from '_interfaces';
import { accountService } from '_services';


interface IAppProps {
  account: ISubscriber | null,
  updateSubscriber: any,
}

const Profile: React.FunctionComponent<IAppProps> = ({ account, updateSubscriber }) => {

  const [ isEditing, setIsEditing ] = useState<boolean>(false);

  const onSubmit = async (data: any, actions: any) => {

    if (data.password === '') {
      delete(data.password);
      delete(data.confirmPassword);
    }

    const subscriber: ISubscriber = {
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
    }

    try {
      const response = await accountService.updateAccount(subscriber);
      updateSubscriber(response.account);
      setIsEditing(false);
    } catch (err) {
      actions.setStatus(`${err.message}`);
      throw Error(err.message);
    }

  }


  return (
    <ProfileForm
      subscriber={account}
      onSubmit={onSubmit}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
    />
  );
}

export { Profile }