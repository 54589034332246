import React from 'react';
import { Section, SectionDefaultLink } from '../_constants';
import { ISubscriber } from '../_interfaces';

interface IAppProps {
  mainTitle: string;
  linkTitle: string;
  onClick: any;
  section: Section;
  account: ISubscriber | null;
}
const SectionAllLink:React.FunctionComponent<IAppProps> = ({mainTitle, linkTitle, onClick, section, account}) => {
  return (
    <div className="col justify-content-center text-center view-all">
    <h3>{mainTitle}</h3>
    {account?.activeSubscription ? (
      <a href={SectionDefaultLink.get(section)} className="text-primary fw-bold text-decoration-none">{linkTitle}</a>
    ) : <button role="link" className="default-link" onClick={onClick}>{linkTitle}</button>}
  </div>
  );
}

export { SectionAllLink }