import React, { useState } from 'react';
import { SubscribeNowButton } from '..';
import { ModalEmailRegistration } from '..';
import { ISubscriber } from '_interfaces';

const SignUpNow:React.FunctionComponent<{account: ISubscriber | null}> = ({account}) => {


  const [showEmailForm, setShowEmailForm] = useState(false);
  const handleClose = () => setShowEmailForm(false);
  const loggedIn = account !== null;
  const subscribed = loggedIn && account?.activeSubscription;

  return (
    <>
    <ModalEmailRegistration 
    show={showEmailForm}
    handleClose={handleClose}
    />

    <section id="signup-now">
      <div className="d-grid gap-2 d-sm-flex justify-content-sm-center my-3">
        {subscribed ? <></> : loggedIn ?
        <div>
        <div className="text-primary text-center mb-2">
          <del>$8 per month</del> $5 per month*
          <br/><span className="billed-footnote">* Billed at an annual rate</span>
        </div>
        <SubscribeNowButton />
        </div>
        :
        <button type="button" className="btn bg-primary btn-lg px-5 text-white sign-up-now " onClick={()=>setShowEmailForm(true)}>SIGN UP NOW</button>
        }
      </div>
      {!subscribed && 
      <p className="text-center fw-bold">to save an average of
      <br/><span className="fs-1">$1500</span>
      <br/> per year

      </p>

}
    </section>
    </>
  );
}

export { SignUpNow }