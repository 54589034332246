import { IHotel, IMenuItem, IMovie, ITicket } from "../_interfaces";

class DataService {

  getMovies(): IMovie[] {
    return [
      {
        title: 'Cruella',
        thumbnail: '/images/movies/cruella.jpg',
        regularPrice: 15.00,
        discountPrice: 9.00,
        logo: '/images/logos/regal.png',
        dealUrl: 'https://memberdeals.com/enjoythetown/packages.php?sub=packages&action=view&id=96'
      },
      {
        title: 'Raya',
        thumbnail: '/images/movies/raya.jpg',
        regularPrice: 12.50,
        discountPrice: 9.25,
        logo: '/images/logos/cinemark.png',
        dealUrl: 'https://memberdeals.com/enjoythetown/packages.php?sub=packages&action=view&id=197'
      },
      {
        title: 'Mortal Kombat',
        thumbnail: '/images/movies/mortal-kombat.jpg',
        regularPrice: 15.50,
        discountPrice: 9,
        logo: '/images/logos/amc.png',
        dealUrl: 'https://memberdeals.com/enjoythetown/packages.php?sub=packages&action=view&id=98'
      }
    ]
  }

  getSports(): ITicket[] {
    return [
      {
        title: 'Baseball',
        thumbnail: '/images/sports/baseball.png',
        discountPrice: 73,
        regularPrice: 92,
        percentOff: 20,
        dealUrl: 'https://memberdeals.com/enjoythetown/preferred_access.php?sub=category&id=45',
        overlayText: {
          title: 'Boston Red Sox at NY Yankees',
          subtitle: 'Yankee Stadium, Bronx, NY'
        },
        subTitle1: 'Section 238 Row 16',
      },
      {
        title: 'Basketball',
        thumbnail: '/images/sports/basketball.png',
        percentOff: 20,
        dealUrl: 'https://memberdeals.com/enjoythetown/preferred_access.php?sub=category&id=99',
      },
    ]
  }

  getThemeParks(): ITicket[] {
    return [
      {
        title: 'Six Flags',
        thumbnail: '/images/themeparks/sixflags.png',
        percentOff: 65,
        dealUrl: 'https://memberdeals.com/enjoythetown/pages.php?sub=sixflags',
      },
      {
        title: 'Universal',
        thumbnail: '/images/themeparks/universal.png',
        percentOff: 13,
        dealUrl: 'https://memberdeals.com/enjoythetown/pages.php?sub=usf',
      },
    ]
  }

  getThemeParkLoggedIn(): ITicket {
    return {
      title: 'SixFlags.com',
      altTitle: 'EnjoyTheTown Members',
      discountPrice: 31,
      regularPrice: 85,
      overlayText: {
        title: 'Six Flags'
      },
      thumbnail: '/images/themeparks/sixflags2.png',
      dealUrl: 'https://memberdeals.com/enjoythetown/pages.php?sub=sixflags',
    }
  }

  getConcert(): ITicket {
    return {
      title: 'Concerts',
      thumbnail: '/images/concerts/eric-church.jpg',
      discountPrice: 62,
      regularPrice: 78,
      dealUrl: 'https://memberdeals.com/enjoythetown/pages.php?sub=all-shows-events',
      overlayText: {
        title: 'Eric Church',
        subtitle: 'Wells Fargo Center, Philadelphia, PA'
      },
      startDate: 'Oct 9, 2021',
      subTitle1: 'Section 238 Row 7',
    }
  }

  getConcertAnon(): ITicket {
    return {
      title: 'Concerts',
      thumbnail: '/images/concerts/events.png',
      percentOff: 20,
      dealUrl: 'https://memberdeals.com/enjoythetown/pages.php?sub=all-shows-events',
    }
  }

  getHotels(): IHotel[] {
    return [
      {
        title: 'Downtown New York',
        altTitle: 'Millenium Hilton NY Downtown',
        regularPrice: 229,
        discountPrice: 156,
        city: 'New York City',
        stars: 5,
        thumbnail: '/images/hotels/downtown-nyc.jpg',
        dealUrl: 'https://memberdeals.com/enjoythetown/hotels.php?sub=details&id=364452_we',
        startDate: 'Aug 7, 2021',
        endDate: 'Aug 14, 2021',
      },
      {
        title: 'Las Vegas',
        altTitle: 'Red Rock Casino Resort and Spa',
        regularPrice: 175,
        discountPrice: 110,
        city: 'Las Vegas',
        stars: 5,
        thumbnail: '/images/hotels/red-rock-las-vegas.png',
        dealUrl: 'https://memberdeals.com/enjoythetown/hotels.php?sub=details&id=461590_we',
        startDate: 'August 14, 2021',
        endDate: 'August 21, 2021',
      },
      {
        title: 'Orlando Area',
        altTitle: 'Waldorf Astoria Orlando',
        regularPrice: 306,
        discountPrice: 194,
        city: 'Orlando',
        stars: 5,
        thumbnail: '/images/hotels/waldorf-orlando.png',
        dealUrl: 'https://memberdeals.com/enjoythetown/hotels.php?sub=details&id=388123_we',
        startDate: 'Aug 7, 2021',
        endDate: 'Aug 14, 2021',
      },
      {
        title: 'Nashville',
        altTitle: 'Dream Nashville',
        regularPrice: 450,
        discountPrice: 312,
        city: 'Nashville',
        stars: 5,
        thumbnail: '/images/hotels/dream-nashville.png',
        dealUrl: 'https://memberdeals.com/enjoythetown/hotels.php?sub=details&id=1038967_k',
        startDate: 'August 11, 2021',
        endDate: 'August 18, 2021',
      },
      {
        title: 'Hawaii',
        altTitle: 'Mauna Kea Beach Hotel',
        regularPrice: 913,
        discountPrice: 779,
        city: 'Hawaii',
        stars: 5,
        thumbnail: '/images/hotels/mauna-kea-beach-hotel.jpg',
        dealUrl: 'https://memberdeals.com/enjoythetown/hotels.php?sub=details&id=431899_l',
        startDate: 'August 13, 2021',
        endDate: 'August 20, 2021',
      },
    ]
  }

  getMenuItems(): IMenuItem[] {
    return [
      { label: 'All', link: 'https://memberdeals.com/enjoythetown/' },
      { label: 'Rental<br/>Cars', link: 'https://memberdeals.com/enjoythetown/discounts.php?sub=viewcat&cat=1' },
      { label: 'Gift<br/>Cards', link: 'https://memberdeals.com/enjoythetown/pages.php?sub=giftcards' },
      { label: 'Choose<br/>A City', link: 'https://memberdeals.com/enjoythetown/cities.php' },
      { label: 'Hotels', link: 'https://memberdeals.com/enjoythetown/hotels.php?sub=alldestinations' },
      { label: 'Theme Parks<br/>&amp; Attractions', link: 'https://memberdeals.com/enjoythetown/pages.php?sub=all-theme-parks-attractions' },
      { label: 'Movie<br/>Tickets', link: 'https://memberdeals.com/enjoythetown/pages.php?sub=movie' },
      { label: 'Events,<br/>Shows &amp; Sports', link: 'https://memberdeals.com/enjoythetown/pages.php?sub=all-shows-events' },
      { label: 'Water<br/>Parks', link: 'https://memberdeals.com/enjoythetown/pages.php?sub=waterparks#view-all'},
      { label: 'Preferred Access™<br/><span class="smallmenu">Sports,Concerts,Major Events &amp More</span>', link: 'https://memberdeals.com/enjoythetown/preferred_access.php' }
    ];
  };
}

const dataService = new DataService();
export { dataService }