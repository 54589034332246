/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { ISubscriber, ITicket, ITicketModal, IMenuItem } from '_interfaces';
import { ModalSubscriptionRequired } from '_components/_modals';
import { dataService } from '_services';
import { MenuLabel } from '_common';

//@TODO: RT refactor this to just use LoggedInMenu.
// had to do brute-force code to meet deadline
const LoggedInMenuB: React.FunctionComponent<{ account: ISubscriber | null }> = ({ account }) => {
  const [item, setItem] = useState<ITicketModal>({} as ITicketModal);

  const onMenuItemClick = (e: any, menuItem: IMenuItem) => {
    if (account !== null && account.activeSubscription) {
      window.location.href = menuItem.link;
    } else {
      setItem({ticket: {} as ITicket, show: true});
    }
  }  

  const items: IMenuItem[] = dataService.getMenuItems();

  return (
    <>
    <ModalSubscriptionRequired item={item} onHide={() => setItem({ticket: {} as ITicket, show: false})} />

    <div className={`container`} id="menu-b">
      {items.map((item: IMenuItem, i: number) => <div className="row bg-primary m-1" key={`menu-${i}`}><div className="col col-sm-12 col-md-12 menuitem mx-1 align-self-center"><a
        className="text-white text-decoration-none text-uppercase"
        onClick={(e) => { onMenuItemClick(e, item) }}
      >
        <MenuLabel text={item.label} />
      </a></div></div>)}
    </div>
    </>
  );
}

export {
  LoggedInMenuB
}