import React, { useState, useEffect } from 'react';
import { accountService } from '_services';

const ConfirmEmail = (props: any) => {
  const authID = props.match.params.authID;
  const [isComplete, setIsComplete] = useState<Boolean>(false);
  const [isError, setIsError] = useState<Boolean>(false);

  useEffect(() => {
    accountService.confirmEmail(authID)
    .then( (result: any) => {
      setIsComplete(true);
    })
    .catch( (error: any) => {
      setIsError(true);
    })
  }, [isComplete, authID, isError]);

  return(
    <div className="container">
      { isError ?
      <div>An error occurred trying to validate the email address.</div>
      :
      (isComplete) ?
        <div>Thank you for confirming your email.</div>
        :
        <div>Confirming email...</div>
      }
    </div>
  );
}

export { ConfirmEmail }