import React, { useState } from 'react';
import { ITicket, ISectionProps, ITicketModal, ISubscriber } from '_interfaces';
import { ModalSubscriptionRequired } from '..';
import { Section } from '_constants'
import { SectionAllLink } from '..';
import { dataService } from '_services';
import { Blurb } from '_common';


interface IAccountProps {
  ticket: ITicket,
  account: ISubscriber | null,
}

const Box: React.FunctionComponent<IAccountProps> = ({ ticket, account }) => {
  return (
      <div className="ticket-container">
        <div className="ticket-poster"><img className="img-fluid" src={ticket.thumbnail} alt={ticket.title} /></div>
        {account !== null && ticket.overlayText &&
          <div className="ticket-overlay-container">
            <div className="ticket-overlay-title">{ticket.overlayText?.title}</div>
            <div className="ticket-overlay-subtitle">{ticket.overlayText?.subtitle}</div>
            <div className="ticket-overlay-subtitle-2">{ticket.startDate}</div>
            <div className="ticket-overlay-subtitle-2">{ticket.subTitle1}</div>
          </div>
        }

        {account === null &&
          <div className="pricing">
            <span className="percent-off">{ticket.percentOff}% <br />off</span>
          </div>
        }

        {account !== null &&
          <div className="pricing hotel discounted">
            <span className="small"><del>${ticket.regularPrice}</del></span>
            <span className="percent-off">${ticket.discountPrice}</span>
          </div>
        }

      </div>
  );
}

/*
const Blurb: React.FunctionComponent<{handleShow: any}> = ({handleShow}) => {
  return (
    <div className="blurb border-start border-5 border-primary px-3">
      <h4>Concerts</h4>
      <span className="fw-bold">Save 20% and get Preferred Access(TM)</span> to<br />
      tickets not available to the public.<br />
      <span className="fw-bold">Save an average of $40 to $400 per person.<br />
        $400 per year.</span>
      <div className="see-latest" onClick={handleShow}>See Latest Concert Deals &gt;</div>
    </div>
  );
}
*/

const Concerts: React.FunctionComponent<ISectionProps> = ({ handleShow, account }) => {
  const [item, setItem] = useState<ITicketModal>({} as ITicketModal)

  const ticket: ITicket = dataService.getConcert();
  const anonTicket: ITicket = dataService.getConcertAnon();

  const loggedIn = account !== null;

  const handleShowTicket = (ticket: ITicket) => {
    setItem({ ticket: ticket, show: true })
  };

  const handleCloseTicket = () => {
    setItem({ ticket: {} as ITicket, show: false });
  }

  const onTicketClick = (e: any, ticket: ITicket) => {
    if (loggedIn) {
      if (account?.activeSubscription) {
        window.location.href = ticket.dealUrl ? ticket.dealUrl : '/';
      } else {
        handleShowTicket(ticket)
      }
    } else {
      handleShow(e);
    }
  }

  return (
    <section id="concerts-section">

      <ModalSubscriptionRequired item={item} onHide={handleCloseTicket} />

      <div className="container">
        {(loggedIn) &&
          <SectionAllLink
            mainTitle='Concert Tickets'
            linkTitle='View All Concert Ticket Deals &gt;'
            onClick={() => setItem({ ticket: {} as ITicket, show: true })}
            section={Section.ConcertTickets}
            account={account}
          />

        }
        <div className="row justify-content-md-center">
          {!loggedIn &&
            <div className="col-md d-flex align-items-center mb-3">
              <Blurb handleShow={handleShow} text="See Latest Concert Deals" arrowDirection="right">
                <h4>Concerts</h4>
                <span className="fw-bold">Save 20% and get Preferred Access(TM)</span> to<br />
                tickets not available to the public.<br />
                <span className="fw-bold">Save an average of $40 to $400 per person.<br />
                $400 per year.</span>
              </Blurb>
            </div>
          }
          <div className={`col-md-${loggedIn ? '6' : 'auto'}`} onClick={(e) => { onTicketClick(e, loggedIn ? ticket : anonTicket) }}><Box account={account} ticket={loggedIn ? ticket : anonTicket} /></div>
          {!loggedIn && <div className="col-md-1"></div>}
        </div>
      </div>



    </section>
  );

}

export { Concerts }