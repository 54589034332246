import React from 'react';
import { SubscribeNowButton } from '.';

const Subscribe = (props: any) => {

  return (
    <section id="subscribe">
      <div className="text-center bg-lightblue">
          <div className="m-5 py-5">
            <h3 className="fw-bold text-black">Subscribe to Purchase Deals</h3>
            <div className="col-lg-6 mx-auto">
              <div className="d-grid gap-2 d-sm-flex justify-content-sm-center my-3">
                <SubscribeNowButton />
              </div>
              <div>
                <del><span className="text-primary">$8/mo</span></del>
                <br/><span className="text-primary fw-bold text-larger">Just $5/mo* For A Limited Time</span>
                <br/><span style={{fontSize: '0.6em', fontWeight: 'normal'}}>* Billed at an annual rate</span>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
}

export { Subscribe }