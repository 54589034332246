import React from 'react';
import { Modal } from 'react-bootstrap';
import { EmailRegistrationForm } from '_components/_forms';

const ModalEmailRegistration = (props: any) => {

  const { show, handleClose } = props;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Free Registration</Modal.Title>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body>
      <div className="row justify-content-center">
        <div className="col-md-5">
        <EmailRegistrationForm />
        </div>
      </div>

      </Modal.Body>
    </Modal>


  );
}

export { ModalEmailRegistration }