import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { ISubscriber } from '../_interfaces';

interface IAppProps {
  subscriber: ISubscriber | null,
  onSubmit: any,
  isEditing: any,
  setIsEditing: any,
}


const AccountReadOnly: React.FunctionComponent<{ setIsEditing: any, subscriber: ISubscriber }> = ({ setIsEditing, subscriber }) => {
  return (
    <div className="card">
      <div className="card-header"><h4>Account Info</h4></div>
      <div className="card-body">

        <table className="table table-hover">
          <tbody>
            <tr>
              <td>User Name</td>
              <td>{subscriber.username}</td>
            </tr>
            <tr>
              <td>First Name</td>
              <td>{subscriber.firstName}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>{subscriber.lastName}</td>
            </tr>
            <tr>
              <td>Email Address</td>
              <td>{subscriber.emailAddress}</td>
            </tr>
          </tbody>
        </table>

        <button className="mt-2 btn btn-primary" type="button" onClick={() => setIsEditing(true)}>Edit</button>

      </div>
    </div>
  );
}

const ProfileForm: React.FunctionComponent<IAppProps> = ({ subscriber, onSubmit, isEditing, setIsEditing }) => {

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, 'Password is too short - should be 3 chars minimum.'), //@TODO: increase to 8, alphanumeric 

    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), undefined], 'Passwords must match'),

    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
  });

  const currentSubscriber: any = {
    emailAddress: subscriber ? subscriber.emailAddress || '' : '',
    password: '',
    confirmPassword: '',
    firstName: subscriber ? subscriber.firstName || '' : '',
    lastName: subscriber ? subscriber.lastName || '' : '',
  }

  return (
    <>
      {isEditing ? (
        <Formik
          initialValues={currentSubscriber}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ status, isSubmitting, errors, touched }) => {
            return (
              <Form>
                <div className="form-group">
                  <label>E-Mail Address</label>
                  <div className="my-2 fw-bold">{currentSubscriber.emailAddress}</div>
                </div>

                <div className="form-group mt-2">
                  <label>First Name</label>
                  <Field
                    className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')}
                    name="firstName"
                    type="input"
                    placeholder="Enter First Name" />
                  <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mt-2">
                  <label>Last Name</label>
                  <Field
                    className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')}
                    name="lastName"
                    type="input"
                    placeholder="Enter Last Name" />
                  <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mt-3">
                  <label>Password <br />(leave blank if you do not wish to change it)</label>
                  <Field
                    className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                    name="password"
                    type="input"
                    placeholder="password" />
                  <ErrorMessage name="password" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mt-2">
                  <label>Confirm Password</label>
                  <Field
                    className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')}
                    name="confirmPassword"
                    type="input"
                    placeholder="Confirm Password" />
                  <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                </div>

                {status &&
                  <div className='alert'>{status}</div>
                }

                <button disabled={isSubmitting} className="m-2 mt-4 btn btn-primary" type="submit">Submit</button>
                <button disabled={isSubmitting} className="m-2 mt-4 btn btn-tertiary" type="button" onClick={() => setIsEditing(false)}>Cancel</button>

              </Form>
            );
          }}
        </Formik>
      ) : (
        <>
          {subscriber ? (
            <AccountReadOnly subscriber={subscriber} setIsEditing={setIsEditing} />
          ) : (
            <div>Unexpected Error!</div>
          )}

        </>

      )}
    </>

  );
}

export { ProfileForm }