import React, { useState } from 'react';
import { IMovie, ISectionProps } from '_interfaces';
import { Modal } from 'react-bootstrap';
import { Section } from '_constants'
import { SectionAllLink } from '..';
import { dataService } from '_services'
import { Blurb } from '_common';

const Pricing: React.FunctionComponent<{ movie: IMovie }> = ({ movie }) => {
  return (
    <div className="pricing">
      <span className="regular-price">${movie.regularPrice?.toFixed(2)}</span>
      <span className="discount-price">${movie.discountPrice?.toFixed(2)}</span>
    </div>
  );
}

const MovieBox: React.FunctionComponent<{ movie: IMovie }> = ({ movie }) => {
  return (
    <div className="movie-container m-3">
      <img className="img-fluid" src={movie.thumbnail} alt={movie.title} />
      <Pricing movie={movie} />
      <div className="movie-logo text-center"><img src={movie.logo} alt={movie.title} /></div>
    </div>
  );
}

const onClickSubscribe = () => {
  window.location.href = '/subscription';
}

interface IMovieModal {
  movie: IMovie;
  show: boolean;
}

const ModalMovieDetails: React.FunctionComponent<{ item: IMovieModal, onHide: any }> = ({ item, onHide }) => {

  return (
    <Modal
      show={item.show}
      onHide={onHide}
      animation={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>

      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Subscription Required</Modal.Title>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onHide}></button>
      </Modal.Header>
      <Modal.Body>Save on this deal now as well as an additional $1,500.00 on other great deals!
        <div>
          <button type="button" className="btn btn-primary mt-2" onClick={onClickSubscribe}>Subscribe</button>
        </div>
      </Modal.Body>
    </Modal>
  );
}



const MovieTickets: React.FunctionComponent<ISectionProps> = ({ handleShow, account }) => {
  const [item, setItem] = useState<IMovieModal>({} as IMovieModal)

  const handleShowMovie = (movie: IMovie) => {
    setItem({ movie: movie, show: true })
  };

  const handleCloseMovie = () => {
    setItem({ movie: {} as IMovie, show: false });
  }

  const loggedIn = account !== null;

  const onMovieClick = (e: any, movie: IMovie) => {
    if (loggedIn) {
      if (account?.activeSubscription) {
        window.location.href = movie.dealUrl ? movie.dealUrl : '/';
      } else {
        handleShowMovie(movie)
      }
    } else {
      handleShow(e);
    }
  }

  const movies: IMovie[] = dataService.getMovies();

  return (
    <section id="movie-section">

      <ModalMovieDetails
        item={item}
        onHide={handleCloseMovie}
      />

      <div className="container">
        {loggedIn &&
          <div className="mb-3">
            <SectionAllLink
              mainTitle='Movie Tickets'
              linkTitle='View All Movie Ticket Deals &gt;'
              onClick={() => setItem({ movie: {} as IMovie, show: true })}
              section={Section.Movies}
              account={account}
            />
          </div>
        }

        <div className="row justify-content-center">
        {!loggedIn &&
            <div className="col-sm text-end d-flex align-items-center order-md-1 mb-3">
              <Blurb handleShow={handleShow} text="See Latest Movie Deals" arrowDirection="left">
                <h4>Movie Tickets</h4>
                <span className="fw-bold">Save $5 per ticket at leading cinemas.</span>
                &nbsp; Unlimited.&nbsp;
                <span className="fw-bold">$240/yr savings</span>
              </Blurb>
            </div>
          }

          {movies && movies.length > 0 ? (
            movies.map((movie: IMovie, i: number) =>
              <div key={`movie-${i}`} className="col-auto mx-3 order-md-12" onClick={(e) => { onMovieClick(e, movie) }}><MovieBox movie={movie} /></div>)
          ) : (
            <div>No Data</div>
          )}

        </div>
      </div>

    </section>
  );

}

export { MovieTickets }