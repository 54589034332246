import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FieldWithError } from '_common';
import { accountService, authenticationService } from '_services';
import { ICredentialsRegistration } from '_interfaces';

const EmailRegistrationForm:React.FunctionComponent<any> = (props: any) => {
  const initialValues = {
    password: '',
    confirmPassword: '',
    emailAddress: '',
  };
  const validationSchema = Yup.object().shape({
    emailAddress: Yup.string().email().required('Email is required.'),
    password: Yup.string()
      .required('No password provided.')
      .min(3, 'Password is too short - should be 3 chars minimum.'), //@TODO: increase to 8, alphanumeric 
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), undefined], 'Passwords must match'),
  });

  const onSubmit = async (data: any, actions: any) => {
    const { emailAddress, password, confirmPassword } = data;
    const { setStatus, setSubmitting } = actions;
    setStatus();
    const credentials: ICredentialsRegistration = {
      username: emailAddress,
      emailAddress: emailAddress,
      password: password,
      confirmPassword: confirmPassword,
    }

    try {
      delete credentials.confirmPassword;
      await accountService.register(credentials);
      await authenticationService.login({username: credentials.username, password: credentials.password});
      if (props.onRegister) {
        props.onRegister();
      } else {
        window.location.href = '/';
      }

    } catch (err) {
      setStatus(`Error: ${err.message}`);
    }
    setSubmitting(false);
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, status, touched, isSubmitting }) => (
        <Form className="form">
          <div className="row mb-2">
            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName='emailAddress'
              placeholder='Enter Email Address'
            />
          </div>

          <div className="row mb-2">
            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName='password'
              placeholder='Enter Password'
              type='password'
            />
          </div>

          <div className="row mb-2">
            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName='confirmPassword'
              placeholder='Enter Password Again'
              type='password'
            />
          </div>

          {status &&
            <div className='alert my-3'>{status}</div>
          }

          <div className="mt-1">
            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn-primary btn-md"
            >Submit</button>
          </div>

        </Form>
      )}

    </Formik>
  );
}

export { EmailRegistrationForm }