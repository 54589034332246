import React from 'react';

const TermsAndConditionsSale = () => {
  return(
<div>

<h2>TERMS AND CONDITIONS OF SALE</h2>
<p className='text-upper'>PLEASE READ THIS DOCUMENT CAREFULLY. WHILE WE ARE VERY APPRECIATIVE OF YOUR BUSINESS, IT IS OUR OBLIGATION TO PROVIDE THIS INFORMATION TO YOU. IT CONTAINS VERY IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS, INCLUDING LIMITATIONS AND EXCLUSIONS THAT MIGHT APPLY TO YOU.</p>

<p className="fst-italic">EnjoyTheTown.com</p>

<p>The following terms and conditions apply to the purchase and sale of a membership subscription (collectively, "Membership” through www.EnjoyTheTown.com (referred to as “EnjoyTheTown"). By purchasing a Membership to EnjoyTheTown.com, you agree to be bound by and accept these terms and conditions. If you do not agree to these terms and conditions, you should not purchase Products from this Website. EnjoyTheTown.com provides its services through a partnership with MemberDeals.com. All purchases and activity at the MemberDeals.com website shall be subject to the terms and conditions below as shown for MemberDeals.com. EnjoyTheTown.com links to various products and services at MemberDeals.com, which are only available to EnjoyTheTown.com members. Your access to the MemberDeals.com website and its products and services is subject to your good standing as a paid member of EnjoyTheTown.com. </p>

<p>EnjoyTheTown reserves the right to modify the content, type and availability of any digital product at any time. Only one person may use the user name and password associated you’re your account at EnjoyTheTown. </p>

<p>We accept major credit cards, PayPal and certain digital wallet services. All prices are in United States Dollars, unless otherwise stated.</p>

<p>We reserve the right to reject any order or purchase at any time.  If your initial payment authorization is later revoked, your subscription, product or access will be terminated. Contact us if you believe your access was terminated in error.</p>

<p>EnjoyTheTown membership subscriptions are renewed automatically. When we renew your subscription, we will use the payment method currently associated with your account.
All charges are nonrefundable, however, you may cancel your subscription at any time to prevent automatic rebilling of your subscription after your current membership expires. Cancellations are effective the following billing cycle. You will not receive a refund for the current billing cycle. You will continue to have the same access and benefits of your product for the remainder of the current billing period.  We reserve the right to issue refunds or credits at our sole discretion. If we issue a refund or credit, we are under no obligation to issue the same or similar refund in the future.</p>


<p>We reserve the right to change prices and fees at any time. We will notify you in advance if the regular rate of a product changes from what was stated at the time of your order. You will have the opportunity to accept the new price or cancel your subscription or purchase from that point forward. We will charge or debit your payment method at the beginning of your subscription. Billing will continue according to the cycle stated at the time of your order. In most cases, you will not be notified in advance of impending renewals and you expressly agree to waive the application of New York General Obligations Law section 5-903 and any similar laws.</p>

<p>We reserve the right to suspend or terminate your subscription or the EnjoyTheTown product for any reason, with or without notice and without further obligation. You will not be entitled to a refund in these circumstances. If any or all of our digital products are temporarily unavailable, you will not receive a refund. We reserve the right to issue refunds or credits at our sole discretion. If we issue a refund or credit, we are under no obligation to issue the same or similar refund in the future.</p>

<p>If your credit card expires or your payment method is otherwise invalid, your subscription or product will not automatically be terminated. You will remain responsible for all charges.
You will be responsible for all costs we incur in connection with the collection of unpaid amounts, including court costs, attorneys' fees, collection agency fees and any other associated costs.</p>

<p>We reserve the right to make changes to our digital products at any time. If we temporarily reduce or eliminate the charge for content or access that you are currently paying for under different terms, you will not receive a refund.</p>

<p>If you have purchased your product through a third party, these Terms of Sale may not apply to you. We are not liable to you for any claims related to purchases made through third parties. Please contact the third party directly.</p>



<p className="fst-italic">MemberDeals.com</p>
<p>The following terms and conditions apply to the purchase and sale of products and services (collectively, "Product(s)") through www.memberdeals.com (referred to as the "Website"). By making purchases through the Website, you agree to be bound by and accept these terms and conditions. If you do not agree to these terms and conditions, you should not purchase Products from this Website. These terms and conditions are subject to change by EnjoyTheTown and/or Entertainment Benefits Group, LLC d/b/a Member Deals (referred to herein as "us", "we" or "our") at any time without prior written notice, in our sole discretion, and shall be effective as of the Effective Date described at the bottom of these terms and conditions. The latest version of the terms and conditions will be posted on this Website, and you should review these terms and conditions prior to purchasing any Product available through this Website. These terms and conditions are an integral part of the Terms of Use that apply generally to the use of our Website. In the event of a conflict between the Terms of Use and these Terms and Conditions of Sale, these Terms and Conditions of Sale shall apply. You should also carefully review our <a href="/privacy-policy">Privacy Policy</a> before making a purchase through this Website. Additional terms and conditions may be applicable to you and any purchase you make through the Website, and such terms and conditions will be posted on the Website where applicable. In the event of a conflict between these Terms and Conditions of Sale, and the Shipping and Delivery Terms and Conditions or the Taxes and Fees terms, the latter terms shall apply.</p>

<p> <span className="fw-bold">1. Who You Are Buying From.</span><br/>
We act as a limited agent or independent seller for those third-party businesses that are promoting or otherwise providing the Products for which you purchase tickets or vouchers, such as venues, teams, artists, nightclubs, hotels, travel companies, rental companies and promoters (each a "Supplier"). Our Website also serves as a marketplace that allows you to buy tickets from third-party ticket brokers and consumer resellers (collectively, "Third-Party Sellers"). We are not, and Third-Party Sellers need not be, affiliated with any Supplier. WE ARE PROVIDING ACCESS TO THE SUPPLIERS’ PRODUCT — NOT THE PRODUCT ITSELF. WE HAVE NO CONTROL OVER THE SUPPLIERS’ ACTUAL PRODUCTS. YOUR DISSATISFACTION WITH A SUPPLIER’S PRODUCT SHALL BE ADDRESSED WITH THE APPLICABLE SUPPLIER; HOWEVER, ANY REFUNDS OR EXCHANGES SHALL BE GOVERNED BY SECTION 6 OF THESE TERMS AND CONDITIONS OF SALE. We will be handling the transaction and collecting payment, and if the tickets or vouchers are sent, they will be sent from the United States, regardless of what country you live in. You acknowledge that some Suppliers may require you to sign their liability waiver prior to participating in their Product. You understand that any violation of any such Supplier’s rules and restrictions may result (a) in cancellation of your purchase, (b) in your being denied access to the applicable Product, and (c) in your forfeiting any monies paid for such Product. Please ensure you read the full terms and conditions of the applicable Supplier, which may be found on the Supplier’s website or by contacting the Supplier directly. Tickets or vouchers obtained from unauthorized sources – sources other than directly through us – may be lost, stolen or counterfeit, and if so are void.</p>
<p> <span className="fw-bold">2. Order Acceptance and Cancellation.</span><br/>
Your receipt of an electronic or other form of order confirmation does not signify our acceptance of your order, nor does it constitute confirmation of our offer to sell. We reserve the right at any time after receipt of your order to accept, decline, or limit your order for any reason, whether or not your credit card has been charged. If your credit card has been charged and we cancel your order, either in whole or in part, you will receive a refund credit to your account for the cancelled Product. We reserve the right at any time after receipt of your order, without prior notice to you, to supply less than the quantity you ordered of any item.
Unless otherwise explicitly set forth herein, once an order has been placed, it cannot be canceled unless the shipment is unavoidably delayed. In this case, we will do our best to cancel the order if requested.
Additionally, if your order requires you to pick up your tickets or vouchers at any hotel destination or will-call office, your order shall be deemed accepted upon your receipt from us of an electronic or other form of order confirmation; subject, however, to the first paragraph of this Section 2. Your failure to pick up your order as described at the time of purchase shall not be deemed a rejection by you of your order and shall not relieve you of any payment or credit card charge for such order.
We do not accept orders from dealers, exporters, wholesalers, or other customers who intend to resell the Products which are offered on our Website; and, in the event we determine a Product has been purchased with the intent to re-sell it by any such person or entity, we reserve the right to cancel or void the transaction without notice.
We make every effort to maintain the availability of our Website; however, should we experience technical difficulties, we are not responsible for orders that are not processed or accepted.
If you do not receive a confirmation (in the form of a confirmation page or email) after submitting payment information, or if you experience an error message or service interruption after submitting payment information, it is your responsibility to confirm with our Customer Service whether or not your order has been placed or received by us. Only you may be aware of problems that may occur during the purchasing process. We will not be responsible for losses (monetary or otherwise) if you assume that an order was not placed because you failed to receive confirmation, nor if you erroneously assume the order was placed.</p>
<p> <span className="fw-bold">3. Payment Terms.</span><br/>
We may accept Visa®, Mastercard®, American Express® and Discover® cards for all purchases, as well as certain Member Deals-branded gift cards, Paypal® and international credit cards for certain purchases, in our sole discretion. You represent and warrant to us that (a) the credit card information or other payment information you supply to us is true, correct and complete, (b) charges incurred by you will be honored by your credit card company or other finance company, and (c) you will pay charges incurred by you at the posted prices, including all applicable taxes, if any. By placing your order, you authorize us to charge your method of payment for the total amount, which includes the ticket price and any taxes and/or fees.
Payment must be received by us prior to our acceptance of an order. All payments must be in United States dollars. Current billing address, email address and phone number must be included with every order if any such information is requested by us. You agree to pay interest on all past-due sums at the highest rate allowed by applicable law. In the event that you dispute a charge and it is determined that the charge was valid and not the result of credit card or other payment fraud, we have the right to seek payment, including all associated fees, by whatever means necessary, including using collection agencies and legal proceedings. We retain a security interest in the Products and all proceeds thereof until the full purchase price therefore (including taxes, fees and any additional charges) has been paid. Without limiting the foregoing, we reserve the right to mitigate its damages by relisting and selling the tickets or vouchers that are the subject of the underlying payment dispute.
Charges for shipping and handling will be made in accordance with our then-current shipping policies, as described below.</p>
<p> <span className="fw-bold">4. Changes in Products and Pricing.</span><br/>
We are constantly updating and revising our offerings of Products, and we may discontinue Products at any time without notice to you. To the extent that we provide information on availability of Products, you should not rely on such information, and we will not be liable for any lack of availability of Products that you may wish to order through our Website.
All pricing for the Products available on our Website is subject to change. For all of our prices and Products, we reserve the right to make adjustments due to changing market conditions, product discontinuation, price changes, errors in advertisements and other extenuating circumstances.
In the event the amount you pay for a ticket or voucher is incorrect, regardless of whether such error is due to an error in a price posted on this Website or otherwise communicated to you, or due to a human error or a transactional malfunction of this Website or other Member Deals-operated system, then we shall have the right (but not the obligation) to cancel that ticket or voucher (or the order for that ticket or voucher) and refund to you the amount that you paid. Alternatively, we may offer the tickets to you at the corrected price. If the corrected price is not acceptable to you, we will allow you to cancel your order.</p>
<p> <span className="fw-bold">5. Taxes & Fees.</span><br/>
While we detail the taxes and fees we charge on the Website located on the Checkout page of our Website, we have provided them below for your convenience.
(a) Generally.
The following information details the taxes and fees that may be applied to your order, when purchasing tickets, hotel reservations and other travel arrangements with us. We hope that you find this information helpful. Member Deals has offices and operates out of several states which dictate the applicable sales taxes that, in some cases, must be collected and remitted on the local or state government's behalf. Similarly, our Suppliers may operate in the same or additional states creating a sales tax liability that is passed on to us and recovered to the extent required by our Suppliers or applicable laws.
(b) Tickets and Travel.
Unless otherwise noted, prices shown for travel packages are in United States Dollars for one adult, and if the travel package includes a hotel, based upon double occupancy. Prices are subject to change based upon your choice of travel dates, number of travelers, departure city, and your choice of flight, hotels or other items, and may require a minimum nights' stay. For ticket orders, the information and all terms and conditions regarding the ticket Products will be listed on our website for you to review prior to purchase. In most instances, ticket orders are non-refundable and non-transferable. We are able to offer the most competitive pricing and product selection by eliminating excessive service fees and relying on our database to connect directly to most of our suppliers. This prohibits us from being able to offer refunds and/or cancellations. Amounts displayed in the "Taxes & Fees" line for ticket transactions consist of the amount that we expect the relevant attraction/event/travel supplier to bill us for any applicable taxes, governmental fees and other charges, as outlined above plus an additional processing service fee that we retain as further described herein.
(c) Hotel Transactions.
In order to provide you with the best offers available, we provide and market hotel rooms on the site on a prepaid model, where we collect the full amount from you up front and handle the payments to the hotel. Amounts displayed in the "Taxes & Fees" line for hotel transactions consist of the amount that we expect the relevant hotel to bill us for any applicable taxes, governmental fees and other charges, as outlined above plus an additional processing service fee that we retain as described below. With respect to hotel Products, the rates offered on this Website are special low rates for pre-paid reservations. Please note that incidentals such as valet, parking, resort fees, room service, energy fees, etc. will be charged directly by the hotel. You should confirm the existence of any such fees directly with the hotel.
(d) Additional Information.
If applicable, information regarding taxes, governmental fees and other charges on any travel-related transactions not discussed above will be calculated and displayed to you prior to check-out. Also, remember that you may incur other charges while traveling that are not payable to us and are not included in the quoted price, such as hotel extra-person or extra-bed charges, resort fees, gratuities, parking fees, telephone fees, room service, movies, mini-bar, or rental car insurance, gasoline, and other incidentals.
(e) Shipping Fees.
Some product transactions require the delivery form of shipping. Certain packages may be required to be shipped by UPS to ensure delivery. The shipping fees are standard fees that are delegated to us by our service providers. If you are responsible for any shipping or delivery fees, they will be calculated and displayed to you prior to check-out.
(f) Miscellaneous Other Fees.
Some ticket transactions may be available for electronic ticket delivery and various payment options may be available based on supplier availability. Various service fees and/or restrictions may be applicable. This information will be displayed prior to all purchases and additional delivery options are for your convenience. Certain service fees are charged for processing your ticket orders and travel reservations through our system. These are often bundled into the Taxes and Fees section in order to maintain the opaque nature of the "prepaid" rate, as required by our contracts with our suppliers. This means that, because in many cases we offer exclusive promotions or special discounts, our suppliers do not want their product to be discounted without noting the taxes and fees separately.</p>
<p> <span className="fw-bold">6. Refunds and Exchanges; Fees.</span><br/>
Before making a purchase on the Website, carefully review your event and seat selection, or other information pertinent to your purchase, as may be applicable. Policies set forth by Suppliers, including but not limited to venues, generally prohibit us from issuing exchanges or refunds after a ticket has been purchased or for any lost, stolen, damaged or destroyed tickets. As such, there are no refunds, cancellations or changes after an order has been placed under any circumstances, except as explicitly permitted herein or as we may permit in our sole and absolute discretion. Unless otherwise stated herein, changes, cancellations and refunds, if permitted for the transaction, may carry a fee of 10% of your total order price. We may occasionally offer tickets at a discount after the original on-sale date and will not refund the difference between the original price and the sale price. We will provide you with a full refund for any purchase of a show or event with a specific time and date, if any such show or event is cancelled by the Supplier. For the avoidance of any doubt, a show or event is not cancelled if it is postponed and/or rescheduled.
With respect to hotel products, the rates offered on this Website are rates for pre-paid reservations. Your credit card will be charged the full amount immediately and the total price includes all tax charges and service fees. Hotel cancellations or changes can be made in accordance with the terms and conditions supplied by the applicable Supplier at the time of purchase, or unless otherwise permitted by us in our sole and absolute discretion. In addition to any cancellation or change fee that may imposed by the Supplier, we may impose a $25 cancellation or change fee per room in our sole and absolute discretion. However, please note that for any reservations associated with room type offers explicitly stating "Non-refundable", the standard cancellation policy is not applicable and these room type offers cannot be cancelled by you, or refunded, under any circumstances. Unused rooms and "No-Shows" are Non-Refundable. Arrivals made on the check-in date are considered "no-shows" if you arrive after 11:59pm Eastern Standard Time. Please note that hotels that are also casinos require the guest checking in to be 21 years of age or older. By purchasing any such hotel that is also a casino, you represent and warrant to us that you are at least 21 years of age. For any questions please contact our Customer Service through the toll-free number provided on the Website.
You agree that you will not attempt to evade, avoid, or circumvent any refund prohibitions in any manner with regard to Products you purchased. Without limiting the generality of the foregoing, you will not contact us to seek a refund or exchange from us when we are prohibited from providing one by our Suppliers, and that you will not dispute or otherwise seek a "chargeback" from the company whose credit card you used to purchase Products from this Website. Should you do so, your purchase may be cancelled or inactivated, and we may, in our sole and absolute discretion, (a) refuse to honor pending and future purchases made from all credit card accounts or online accounts on which such chargebacks have been made, (b) prohibit all users in whose name the credit card accounts or online accounts on which such chargebacks have been made, and (c) prohibit all persons in whose name the credit card accounts exist and any person who accesses any associated online account or credit card or who otherwise breaches this provision from using the Website.
If a purchase is made through the use of a credit card, and these terms and conditions or applicable laws require a cash refund, such refund will be issued using the same method of payment that was used for the purchase. However, in the event we allow a return for reasons not otherwise expressly required by these terms and conditions or applicable laws, we may issue a refund in the form of a credit through a credit voucher or Member Deals-branded gift card or gift certificate.
We will not be liable for travel or other expenses that you or anyone else incurs in connection with a cancelled or postponed event.</p>
<p> <span className="fw-bold">7. Hotel-Specific Terms & Conditions; Best Rate Guarantee.</span><br/>
We use a variety of hotel Suppliers in our effort to source the best accommodations, prices and availability of hotel reservations. Our hotel rates are only available through our Website and Customer Service, and cannot be obtained by calling the hotel directly.
Member Deals offers a Best Rate Guarantee based on a Qualifying Rate, described below.
A "Qualifying Rate" (a) applies only to hotel room rates available on public websites for businesses based in the United States, (b) does not include any guest fees, service fees, resort fees, taxes, surcharges, tariffs or other hotel-related charges, and (c) applies only to in-kind hotel bookings (i.e., for the same hotel, same room type, same travel date(s), same room type, same rate type, and for the same number of guests). Member Deals’s "Qualifying Rate" does not apply (a) to rates when they are combined as a package which includes other products or services, (b) to any rates that are offered due to errors or mistakes, (c) to any corporate discounts, reward programs, convention rates, meeting rates, interline rates or hotel promotions, nor (d) to rates offered where the property, company, itinerary or similar booking details are opaque or unknown until after the purchase, or where rates are determined by any sort of auction or bidding.
In the event a booking is subject to Member Deals’s Best Rate Guarantee and you find a rate that is less than the hotel room rate charged by Member Deals, Member Deals will refund you the difference between the amount paid on Member Deals and the lower rate available elsewhere; provided, however, that the maximum refund shall not exceed $150.00; and further provided that in the case that the maximum refund exceeds the original purchase rate, the original purchase rate will be refunded. Should you find a lower available rate that is covered under Member Deals’s Best Rate Guarantee, you must notify Member Deals within 24 hours after your booking with us. Your notification must be in the form of an email and sent to customerservice@memberdeals.com. Your claim request must include the confirmation number, hotel name, travel check-in date and travel check-out date, number of rooms, number of guests, and the hotel website URL in which you are claiming to have a better rate. Please confirm the URL is correct and active. An active link must prove availability of all claims. Claims will not be evaluated or processed if only screenshots are provided. All requests and required documentation are subject to verification by Member Deals.
Each customer shall be limited to three Best Rate Guarantee claims per month, regardless of the number of accounts used by the customer.</p>
<p> <span className="fw-bold">8. Preferred Access Ticket-Specific Terms & Conditions.</span><br/>
You acknowledge that Third-Party Sellers may participate in the practice of reselling tickets (i.e., obtaining tickets from Suppliers on the primary market or from resellers with the intent to resell such tickets in a secondary market); and that such tickets are typically sold by Third-Party Sellers at a price that may be either higher or lower than the "face value" listed on the ticket (each a "Preferred Access Ticket"). By purchasing Preferred Access Tickets through the Website, you agree that the purchase price for Preferred Access Tickets may not reflect the original "face value" of the ticket.
As a marketplace: We do not own the Preferred Access Tickets advertised on the Website; we do not set the prices for the Preferred Access Tickets advertised on the Website; we do not have control over the Third-Party Sellers or their business practices; and, with respect to the Preferred Access Tickets, we are not acting as a primary sale box office or operating agent for the Suppliers.
You are responsible for reading the complete listing before making a purchase. We do not guarantee the accuracy of any information provided by Third-Party Sellers. We will collect your payment for any purchases, ensure that the applicable Third-Party Seller is paid for the Preferred Access Ticket, and ensure that you receive the purchased Preferred Access Tickets in accordance with the terms and conditions contained herein. You cannot change or cancel your purchase at any time or for any reason, except as explicitly described herein.
We may charge service, shipping, delivery, fulfillment or other fees for Preferred Access Tickets purchased through the Website (the "Preferred Access Ticket Fees"). The Preferred Access Ticket Fees will be explained to you prior to your purchase. We reserve the right to change the Preferred Access Ticket Fees at any time, in our sole discretion. The Preferred Access Ticket Fees may not be refundable except as explicitly described herein or required by law. We reserve the right to change the delivery method, at its sole discretion, in order to ensure delivery prior to the scheduled event – you will not be charged for any additional delivery fees.</p>

<p> <span className="fw-bold">Order Confirmation; Processing.</span><br/>
Immediately after you place an order for Preferred Access Tickets, we will send you an email confirming that we have received your order (the "Confirmation Email") and we will begin the process of securing your tickets. Once we have secured your tickets, you will receive a second email that will confirm that we have secured your tickets and that will either provide you with your tickets or explain how you may receive or obtain your tickets (the "Ticket Delivery Email"). For example, and depending on the ticket purchased or fulfillment method selected, we may either send you electronic or physical tickets directly, arrange for a third-party to deliver your tickets, provide you access to your tickets through a third-party's "electronic wallet or account", or you may be required to pick-up your tickets at the Supplier's Will Call office or from the Third-Party Seller. If access to tickets is being provided through a third party's electronic wallet or account, you may be provided with the option of accepting or declining the ticket delivery. Tickets are non-refundable and, as such, declining or otherwise failing to accept the delivery of the tickets DOES NOT cancel the purchase and WILL NOT result in a refund of the purchase. If you place an order and do not receive a Confirmation Email or Ticket Delivery Email, or if you decline or fail to accept the delivery of your tickets, you are responsible for calling our Customer Service to resolve any issues. Your order may be finalized even if you do not receive a Confirmation Email or Ticket Delivery Email. You may NEVER make an assumption about the status of your order because you have not been contacted by us. Orders may not be cancelled due to problems with your receipt of emails.</p>

<p> <span className="fw-bold">Preferred Access Ticket Availability.</span><br/>
All orders are subject to availability. Occasionally tickets ordered may no longer be available at the price or in the quantity originally ordered at the time the order is received. We reserve the right to replace tickets with comparable or better tickets if the originally-ordered tickets are no longer available. If no alternates are available, either your credit card will not be charged at all or the entire amount charged will be refunded, and you will be notified of any such determination. We reserve the right to cancel your order and provide you with a full refund (including any Preferred Access Ticket Fees) at any time for any reason, prior to the scheduled event.</p>

<p><span className="fw-bold">Event Cancellation, Postponement, and other Event Changes.</span><br/>
Should an event be cancelled (and not rescheduled), or should a contingent event (e.g., a playoff game) not occur, you will receive a full refund (including any Preferred Access Ticket Fees) for your purchase.<br/>
Should an event be postponed and/or rescheduled, we will cooperate with you to resolve any issues; including, but not limited to, coordinating the delivery of any additional documentation that you may need in order to attend the rescheduled event; provided, however, that we reserve the right to cancel any orders and provide you with a full refund (including any Preferred Access Ticket Fees) if new tickets are required. Refunds may not be issued for postponed events unless they are ultimately cancelled.<br/>
We are not responsible for partial performances, or venue, line-up, date or time changes. Refunds may not be issued in such circumstances.</p>

<p><span className="fw-bold">Preferred Access Ticket Promise.</span><br/>
We promise that:<br/>
•	You will receive your Preferred Access Tickets before the scheduled event;<br/>
•	Your Preferred Access Tickets will be genuine and valid for entry to the event;<br/>
•	You will receive the Preferred Access Tickets you ordered, or comparable or better tickets will be substituted; and<br/>
•	If the event is cancelled and not rescheduled, you will receive a full refund.</p>

<p>If you do not receive the Preferred Access Tickets before the scheduled event, please notify us as soon as possible. You are responsible for notifying us prior to the scheduled event. Should you fail to notify us prior to the scheduled event, you may not be eligible for a refund based on non-delivery of tickets. Upon such notification, we will, in our sole discretion, attempt to locate and facilitate delivery of your tickets, provide you with comparable or better replacement tickets at no additional cost, or issue you a full refund (including any Preferred Access Ticket Fees).</p>

<p>If you receive tickets that are not the same, comparable or better than the ones you ordered, please notify us within 48 hours of your receipt of the tickets. Should you fail to notify us within 48 hours of your receipt of the tickets, you may not be eligible for a refund based on the accuracy of the tickets. Upon verification by us, and depending on when you notify us, we may do any of the following in its sole discretion: provide you with comparable or better replacement tickets, or issue you a full refund (including any Preferred Access Ticket Fees). We may require that you return the tickets you received in order to obtain a refund, and in such event: tickets must be returned within fourteen days; tickets must be returned via personal delivery, certified mail or using a carrier that can provide you with proof of delivery; and the tickets must be returned to the following address:</p>
<p className="fst-italic">Member Deals<br/>
Attn: Fulfillment Department<br/>
5551 Vanguard Street<br/>
Orlando, FL 32819</p>

<p>As used herein "comparable or better" replacement tickets are determined by us in our sole but reasonable discretion, based on cost, quality, availability and other factors. However, if you initially ordered your seats next to each other, "comparable or better" tickets will keep your seats together.
In no event shall we provide an exchange or refund for any Preferred Access Tickets that are lost, stolen, damaged or destroyed, through no fault of our own. Once delivered, you are solely responsible for ensuring the accuracy and security of the Preferred Access Tickets. No exchanges or refunds shall be provided in the event you fail to comply with the terms and conditions contained herein. The terms and conditions contained herein this Section 8 (Preferred Access Ticket-Specific Terms & Conditions) shall not apply to any product or service purchased on the Website that is not a Preferred Access Ticket, including but not limited to any hotel booking, or any discount or special offer product or service.</p>
<p><span className="fw-bold">9. Shipping Policy.</span><br/>
In addition to offering electronic delivery for many products and services, we offer a variety of shipping options to meet your shipping needs, as further set forth on the Checkout page of our Website. Please check the Checkout page for specific delivery options. All shipping prices are quoted in United States dollars. No C.O.D. orders can be accepted.
Please note the posted shipping time frame is listed on the Checkout page and may vary from item to item. The posted shipping time frame is contingent upon credit card approval and may be delayed should we experience difficulties in obtaining authorization.
These shipping terms are accepted by you by placing an order with us.</p>
<p><span className="fw-bold">10. Advertising Disclaimer and Trademarks</span><br/>
The descriptions of products and services that are posted on our Website are the representations of our Suppliers. We are not responsible for the accuracy of such descriptions, nor are we responsible for typographical, pricing, product information, advertising or shipping errors.
In the event a Product is listed at an incorrect price or with incorrect information due to typographical error or error in pricing or product information received from our Suppliers, we shall have the right to refuse or cancel any orders placed for Products listed at the incorrect price. We shall have the right to refuse or cancel any such orders whether or not the order has been confirmed and your credit card charged. If your credit card has already been charged for the purchase and your order is canceled, we will issue a credit to your credit card account in the amount of the charge.
All trademarks and registered trademarks relating to Products available through our Website are the sole property of their respective owners.</p>
<p><span className="fw-bold">11. Disclaimer and Limitation of Liability</span><br/>
Our responsibility for defects relating to the Products available on our Website is limited to the procedures described in our return policy set forth herein. ALL PRODUCTS AVAILABLE ON THIS WEBSITE ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT THE PRODUCTS AVAILABLE ON THIS WEBSITE WILL MEET YOUR REQUIREMENTS; THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PRODUCTS WILL BE EFFECTIVE, ACCURATE OR RELIABLE; OR THE QUALITY OF ANY PRODUCTS WILL MEET YOUR EXPECTATIONS. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY TO THE EXTENT SUCH JURISDICTION'S LAW IS APPLICABLE TO YOUR PURCHASE.
WE DO NOT ACCEPT LIABILITY BEYOND THE REMEDIES SET FORTH HEREIN, INCLUDING ANY LIABILITY FOR PRODUCTS THAT ARE NOT AVAILABLE FOR USE OR FOR LOST OR CORRUPTED DATA OR SOFTWARE. IN NO EVENT SHALL WE, OR OUR PARENT, SUBSIDIARIES, RELATED COMPANIES, AGENTS, SHAREHOLDERS, EMPLOYEES, OR OFFICERS HAVE ANY OBLIGATIONS OR LIABILITIES TO YOU OR ANY OTHER PERSON FOR LOSS OF PROFITS, FOR LOSS OF BUSINESS OR USE, OR FOR INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, WHETHER BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR ANY OTHER THEORY OR FORM OF ACTION, EVEN IF WE OR THEY HAVE BEEN ADVISED OF THE POSSIBILITY THEREOF, ARISING OUT OR IN CONNECTION WITH THE SALE, DELIVERY, USE, REPAIR OR PERFORMANCE OF THE PRODUCTS AVAILABLE THROUGH THIS WEBSITE. NO EMPLOYEE OR REPRESENTATIVE OF MEMBER DEALS IS AUTHORIZED TO MODIFY THIS LIMITATION.
YOU HEREBY ASSUME ALL RISKS AND DANGER INCIDENTAL TO THE PRODUCT FOR WHICH THE TICKET OR VOUCHER IS ISSUED, WHETHER OCCURRING BEFORE, DURING, OR AFTER THE EVENT OR USE OF THE PRODUCT. YOU HEREBY WAIVE ANY CLAIMS FOR PERSONAL INJURY OR DEATH AGAINST MEMBER DEALS OR ITS RELATED COMPANIES ON BEHALF OF YOURSELF AND ANY ACCOMPANYING MINOR. SHOULD YOU PLACE AN ORDER ON ANY OTHER INDIVIDUAL’S BEHALF, YOU AGREE TO INFORM ANY SUCH INDIVIDUAL OF THE LIMITATIONS ON LIABILITY CONTAINED IN THIS AGREEMENT, AND ANY USE BY ANY SUCH INDIVIDUAL OF ANY TICKET OR VOUCHER SHALL BE DEEMED AN ACCEPTANCE OF THESE TERMS AND CONDITIONS.
OUR SOLE AND ENTIRE MAXIMUM LIABILITY (AND THE LIABILITY OF ANY OF THE SUPPLIERS OF PRODUCTS AND SERVICES AVAILABLE ON OUR WEBSITE), FOR ANY REASON, AND YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY CAUSE WHATSOEVER, SHALL BE LIMITED TO THE ACTUAL AMOUNT PAID BY YOU FOR THE PRODUCT YOU HAVE ORDERED THROUGH OUR WEBSITE.
SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU TO THE EXTENT SUCH JURISDICTION'S LAW IS APPLICABLE TO YOUR PURCHASE OF PRODUCTS.</p>
<p><span className="fw-bold">12. Ticket-Holder Behavior Policy; Ejection and Cancellation.</span><br/>
Venues reserve the right to refuse admission to or eject any person whose conduct such venue’s management deems disorderly, who uses profane, vulgar or abusive language, or who fails to comply with the venue’s rules or policies. In any such event, you shall not be eligible for (and we shall not be obligated to issue you) a refund for any such purchase; and we shall not be liable for any incidental or consequential expenses incurred by you.
Notwithstanding anything herein to the contrary, no refund shall be provided to you in the event you violate the Terms of Use or the Terms and Conditions of Sale of this Website, or in the event you fail to abide by all rules and policies related to the venue where the event is located, which violation or failure results in your inability to gain admittance to the venue or ejection from the venue.
In the event your ejection from a venue results in the loss of a Third-Party Seller’s right to use any other season tickets at that venue, or the right to purchase other tickets from that venue, you shall be liable for all costs, expenses and losses associated with such Third-Party Seller’s loss, including but not limited to all direct, indirect, consequential, exemplary, incidental, special or punitive damages, including but not limited to lost profits; and you shall indemnify, defend and hold us, and our parent companies, subsidiaries and affiliated companies, and each of their direct and indirect owners, officers, directors, employees, agents and insurers, harmless for the same.</p>
<p><span className="fw-bold">13. Warranty and Return Policy.</span><br/>
Most of the Products available through our Website are covered under the Supplier’s warranty, which may be detailed in the Product’s description or on the Supplier’s website. If you have any questions regarding warranties in connection with any Supplier’s Products, you are strongly encouraged to contact such Supplier to determine if the warranty of such Product is acceptable to you. If applicable, such warranties apply from the date of order. You understand that we do not operate or control the Products offered by the Suppliers participating on our Website, and you agree that under no circumstances will we be liable for any damages arising out of the failure of any Supplier to fulfill its obligations to you.</p>
<p><span className="fw-bold">14. Privacy and Customer Information.</span><br/>
We are committed to protecting your privacy. To make your shopping experience more convenient, we gather information from or about you. We maintain the privacy of your information using security technologies and adhere to policies that prevent unauthorized use of your personal information. See our <a href="/privacy-policy">Privacy Policy</a> on this Website for further details.</p>
<p><span className="fw-bold">15. Force Majeure.</span><br/>
In addition to any excuse provided by applicable law, we shall be excused from liability for non-delivery or delay in delivery of Products available through our Website arising from any event beyond our reasonable control, whether or not foreseeable by either us or you, including but not limited to, labor disturbance, war, fire, accident, adverse weather, inability to secure transportation, governmental act or regulation, and other causes or events beyond our reasonable control, whether or not similar to those which are enumerated above.</p>
<p><span className="fw-bold">16. Entire Agreement.</span><br/>
These terms and conditions constitute the entire agreement and understanding between us concerning the subject matter hereof and supersedes all prior agreements and understandings of the parties with respect thereto. These terms and conditions may NOT be altered, supplemented, or amended by the use of any other document(s). Any attempt to alter, supplement or amend this document or to enter an order for Products which are subject to additional or altered terms and conditions shall be null and void, unless otherwise agreed to in a formal written agreement signed by you and us. Except as expressly stated herein, to the extent that anything in or associated with the Website is in conflict or inconsistent with these terms and conditions, these terms and conditions shall take precedence.</p>
<p><span className="fw-bold">17. Enforceability and Severability.</span><br/>
The invalidity or unenforceability of any terms or conditions hereof will in no way affect the validity or enforceability of any other term or provision. If any part of the terms and conditions hereof are determined to be invalid, unenforceable, or contrary to law or professional ethics, that part will be reformed, if possible, to conform to law and ethics and all other parts of these terms and conditions will remain fully effective.</p>

</div>

  );

}

export { TermsAndConditionsSale }