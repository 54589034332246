import React, { useEffect, useState } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import {
  StripeSubscriberSubscriptionForm
} from '.';

import { accountService } from '_services';
import { IOffer } from '_interfaces';

const Subscription = () => {

  const [stripePromise, setStripePromise] = useState<any>(undefined);
  const [offer, setOffer] = useState<any>({});

  useEffect(()=>{

    const _setup = async() => {
      try {
        const offer: IOffer = await accountService.getOffer();
        setOffer(offer);
        const gateway = await accountService.getGatewayInfo();
        setStripePromise(loadStripe(gateway.stripePubKey));
      } catch (error) {
        throw Error(error);
      }
    }

    _setup();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <>
    {stripePromise && offer ? 
    <Elements stripe={stripePromise}>
      <StripeSubscriberSubscriptionForm offer={offer} />
    </Elements>
    : <div>Waiting for API...</div>
    }
    </>
  );
}

export { Subscription as StripeSubscription}