enum Section {
  Hotels = 1,
  Movies = 2,
  Sports = 3,
  ThemeParks = 4,
  ConcertTickets = 5
}

const SectionDefaultLink: Map<number, string> = new Map<number, string>([
  [Section.Hotels, 'https://memberdeals.com/enjoythetown/hotels.php?sub=alldestinations'],
  [Section.Movies, 'https://memberdeals.com/enjoythetown/pages.php?sub=movie'],
  [Section.Sports, 'https://memberdeals.com/enjoythetown/preferred_access.php?sub=category&id=25'],
  [Section.ThemeParks, 'https://memberdeals.com/enjoythetown/pages.php?sub=all-theme-parks-attractions'],
  [Section.ConcertTickets, 'https://memberdeals.com/enjoythetown/preferred_access.php?sub=category&id=72']

]);

export { Section, SectionDefaultLink }