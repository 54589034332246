import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  authenticationService
} from '../_services';

import { FieldWithError } from '../_common';
import { KeyFill, PersonCircle } from 'react-bootstrap-icons';

const LoginForm:React.FunctionComponent<{setShowRegDialog: any, handleCloseSignIn: any}> = ({setShowRegDialog, handleCloseSignIn}) => {

  const onClickRegister = () => {
    handleCloseSignIn();
    setShowRegDialog(true);
  }

  const onSubmit = async (data: any, actions: any) => {
    const { username, password } = data;
    const { setStatus, setSubmitting } = actions;
    setStatus();
    try {
      await authenticationService.login({ username, password });
      const redirURL = '/';
      window.location.href = redirURL;

    } catch (err) {
      setSubmitting(false);
      setStatus(`error occurred: ${err.message}`);
    }

  }

  return (
    <Formik
      initialValues={{
        username: '',
        password: ''
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().required('Username is required'),
        //password: Yup.string().required('Password is required')
      })}
      onSubmit={onSubmit}
    >
      {({ errors, status, touched, isSubmitting }) => (

        <div className="container">
          <div className="wrapper">

            <div className="row justify-content-center">
              <div className="col">

                <Form className="form">

                  <div className="row">
                    <FieldWithError
                      errors={errors}
                      touched={touched}
                      fieldName='username'
                      placeholder='Enter User Name'
                      icon={(<PersonCircle />)}
                    />
                  </div>

                  <div className="row">
                    <FieldWithError
                      errors={errors}
                      touched={touched}
                      fieldName='password'
                      icon={(<KeyFill />)}
                      placeholder='Enter Password'
                      type='password'
                    />
                  </div>

                  <div className="row">
                    <div className="col text-center">
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="btn btn-primary btn-md justify-text-center"
                    >Login</button>

                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col lg-auto text-center">
                    Forgot password? <a href='/forgot-password'>Reset it!</a>
                    </div>
                  </div>

                  <div className="row mt-2">
                  <div className="col lg-auto text-center">
                    Don't have an account? <button type="button" className="btn btn-primary mt-1" onClick={onClickRegister}>Register Here</button>
                    </div>
                  </div>

                  {status &&
                    <div className={'alert alert-danger'}>{status}</div>
                  }

                  {isSubmitting &&
                    <div className="spinner-border"></div>
                  }
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}

export { LoginForm }
