import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { LoggedInMenuB, ModalLogin } from '_components';
import { ISubscriber } from '_interfaces';

interface IAppProps {
  logout: any;
  login: any;
  isLoggedIn: boolean;
  account: ISubscriber | null;
  setAccount: any;
  modalPage: string | null;
}

const Navigation: React.FunctionComponent<IAppProps> = ({ logout, login, account, setAccount, modalPage }) => {

  const [showLoginForm, setShowLoginForm] = useState<boolean>(modalPage === 'signin');

  const onLoginClick = () => setShowLoginForm(true);
  const onLoginClose = () => setShowLoginForm(false);

  const onClickMyAccount = () => {
    window.location.href = '/my-account';
  }

  return (
    <>
      <ModalLogin
        show={showLoginForm}
        handleClose={onLoginClose}
      />

      <Navbar bg="light" variant="light" expand="md" style={{justifyContent: "start"}}>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <div className="d-none d-sm-block m-1"></div>
        <Navbar.Brand href="/"><div className="ett-logo"><img src="/images/logos/ett-logo-large.jpg" alt="Enjoy The Town" /></div></Navbar.Brand>
        <Navbar.Collapse>
          <Nav className="justify-content-end w-100">
            {(account !== null) ? (
              <Nav.Item>
                <div className="btn-toolbar ml-auto">
                  <button type="button" className="btn btn-primary mx-2" onClick={onClickMyAccount}>My Account</button>
                  <button type="button" className="btn btn-primary" onClick={logout}>Sign Out</button>
                </div>
              </Nav.Item>
            ) : (
              <Nav.Item className="ml-auto"><button type="button" className="btn btn-primary" onClick={onLoginClick}>Sign In</button></Nav.Item>
            )}
          </Nav>
          <div>
        </div>
        { account !== null &&
        <LoggedInMenuB account={account}/>
        }

        </Navbar.Collapse>
      </Navbar>


    </>
  );
}

export { Navigation }