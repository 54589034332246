import React from 'react';
import { ConversionPixel } from '_components/ConversionPixel';
//import { authenticationService } from '../../_services';

import Helmet from 'react-helmet';

const ThankYouPage = (props: any) => {

  const onClick = () => {

    //const token: string = authenticationService.getToken();
    const redirURL = '/'; //@TODO: redirect to myaccount page
    window.location.href = redirURL;
  }

  return (
    <div className="wrapper">
      <ConversionPixel />
      <Helmet>
      <script>{`gtag('event', 'conversion', {'send_to': 'AW-576764528/VXujCLaDs9oCEPD0gpMC'});`}</script> 
      </Helmet>
      <div className="row justify-content-center">
        <div className="col">
          <h2>Thank you for subscribing!</h2>

          <p>Let us introduce you to the newest exclusive member savings program Enjoy the Town! Saving your family $1,500 a year just got so much easier! As a new member you can enjoy many amazing different private deals!</p>

          <p>With your subscription you can enjoy these exclusive unlimited savings:</p>
          <ul>
            <li>Preferred Access tickets on sporting events, concerts and many other major events that aren’t available to the public </li>
            <li>365-day customer support where a real human will pick up the phone to help you with bookings, cancellations, or anything you may need</li>
            <li>Save $20 to $100 per night on luxury hotels and villas</li>
            <li>55% off on most movie tickets</li>
            <li>Save over 50% on shows, tours, and attractions</li>
            <li>40% off on Broadway tickets </li>
            <li>Ability to purchase gift cards</li>
            <li>Save on many at home purchases like Verizon Wireless, HP, Dell, Microsoft, subscription boxes, fitness programs, apps, restaurants, etc.</li>
          </ul>

          <div className="btnWrapper">
            <button
              type="submit"
              className="btn btn-primary btn-lg mt-3"
              onClick={onClick}
            >Continue</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ThankYouPage }