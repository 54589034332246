import { SetupIntentResult } from '@stripe/stripe-js';
import axios from 'axios';
import { config } from '../config';
import { authenticationService } from '../_services';

class StripeService {

  constructor(protected apiBaseUrl: string) {}

  /**
   * @param siteUID 
   * @returns {stripeIntentId, stripeClientSecretKey}
   */
  async getPaymentIntent(): Promise<any> {

    const url = `${this.apiBaseUrl}/subscriber/pay/pre-init`;

    try {
      const data: any = await axios.post(url, {},  authenticationService.getAuthHeader());
      return data;
    }
    catch (err) {
      throw Error(err.message);
    }
    
  }

  /**
   * 
   * @param siteUID 
   * @returns 
   */
  async getGatewayInfo(): Promise<any> {
    const url = `${this.apiBaseUrl}/subscriber/pay/gateway-info`;

    try {
      const data: any = await axios.post(url, {},  authenticationService.getAuthHeader());
      return data;
    }
    catch (err) {
      throw Error(err.message);
    }
  }

  async getPublicKey(): Promise<any>{
    const url = `${this.apiBaseUrl}/subscriber/pay/pre-init`;
    try {
      const data: any = await axios.post(url, {}, authenticationService.getAuthHeader());
      return data;
    } catch (err) {
      throw Error(err.message);
    }
  }

  async pay(data: {setupIntentResult: SetupIntentResult, coupon: {code: string} | null}) {
    const url = `${this.apiBaseUrl}/subscriber/pay`;
    const planId = config.aiqOfferId;
    const payload = {
      planId: planId,
      stripeIntentId: data.setupIntentResult.setupIntent?.id,
      coupon: {
        code: data.coupon?.code
      }
    }
    try {
      const result: any = await axios.post(url, payload, authenticationService.getAuthHeader());
      if (result.data.error) {
        throw Error(result.data.error.message);
      }
      return result;
    } catch (err) {
      throw Error(err.message);
    }
  }
}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/${config.siteUID}`;
const stripeService = new StripeService(apiBaseUrl);
export { stripeService }