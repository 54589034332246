import React from 'react';
import { EmailRegistrationForm } from '_components/_forms';
import { ISubscriber } from '_interfaces';

const RegisterAndSubscribe = (props: any) => {

  const onRegisterSuccess = () => {
    document.location.href = '/subscription';
  }

  return (
    <>
      {props.account && props.account !== null ? <AlreadyRegistered account={props.account} /> : <Register onSuccess={onRegisterSuccess} />}
    </>
  );
}

const Register: React.FunctionComponent<{ onSuccess: any }> = ({ onSuccess }) => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h3>Account Registration</h3>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <EmailRegistrationForm onRegister={onSuccess} />
        </div>
      </div>
    </div>
  )
};


const AlreadyRegistered: React.FunctionComponent<{ account: ISubscriber | null }> = ({ account }) => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h3>Page is not available</h3>
          <p>You already have a registration and already logged in.
          <br />Click <a href="/my-account">here</a> to view your account.
          {account !== null && account.activeSubscription === false && <>, <br />Or click <a href="/subscription">here</a> to purchase a subscription.</>}
          </p>
        </div>
      </div>
    </div>
  );
}


export { RegisterAndSubscribe }