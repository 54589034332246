import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { ModalEmailRegistration, ModalLogin } from '..';

const ModalRegistrationRequired = (props: any) => {
  const [showRegForm, setShowRegForm] = useState<boolean>(false);
  const [showLoginForm, setShowLoginForm] = useState<boolean>(false);

  const onRegisterClick = () => {
    setShowRegForm(true);
  }

  const onLoginClick = () => {
    setShowLoginForm(true);
  }

  return (
    <>
    <ModalLogin
      show={showLoginForm}
      handleClose={() => setShowLoginForm(false)}
    />

    <ModalEmailRegistration
      show={showRegForm}
      handleClose={() => setShowRegForm(false)}
    />

    <Modal
      show={props.show}
      onHide={props.handleClose}
      animation={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Free Registration Required</Modal.Title>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.handleClose}></button>
      </Modal.Header>
      <Modal.Body>
        You must be registered or logged in to view the details of this deal.
        <div>
        <button type="button" className="btn btn-primary my-4 float-right" onClick={onRegisterClick}>Register Now - It's FREE!</button>
        <button type="button" className="btn btn-primary mx-2 float-right" onClick={onLoginClick}>Sign In</button>  
        </div>
      </Modal.Body>
    </Modal>
    </>
  );
}

export { ModalRegistrationRequired }