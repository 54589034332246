import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { LoginForm, ModalEmailRegistration } from '_components';

const ModalLogin: React.FunctionComponent<{ show: any, handleClose: any }> = ({ show, handleClose }) => {

  const [showRegDialog, setShowRegDialog] = useState<boolean>(false);

  return (
    <>
      <ModalEmailRegistration
        show={showRegDialog}
        handleClose={() => setShowRegDialog(false)}
      />

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Sign In</Modal.Title>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-5">
              <LoginForm handleCloseSignIn={handleClose} setShowRegDialog={setShowRegDialog}/>
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </>

  );
}

export { ModalLogin }