import React from 'react';
import { ErrorMessage } from 'formik';
import { PersonCircle, GeoAltFill, Globe } from 'react-bootstrap-icons';
import { FieldWithError, StateField } from '_common';
import { IOffer } from '_interfaces';


const CheckoutForm:React.FunctionComponent<{errors: any, touched: any, updateCharge: any, values: any, offer: IOffer}>= ({errors, touched, updateCharge, values, offer}) => {
  return (
    <div className="container">

      <div className="card">
        <h4 className="card-header">Billing Info</h4>
        <div className="card-body">

          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName='firstName'
                  placeholder='Enter First Name'
                  icon={(<PersonCircle />)}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName='lastName'
                  placeholder='Enter Last Name'
                  icon={(<PersonCircle />)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName='billingAddress1'
                  placeholder='Enter Billing Address'
                  icon={(<GeoAltFill />)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName='address2'
                  placeholder='Billing Address 2 (Optional)'
                  icon={(<GeoAltFill />)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName='billingCity'
                  placeholder='Enter Billing City'
                  icon={(<GeoAltFill />)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 mb-3">
                <div className="input-group">
                  <span className="input-group-text"><Globe /></span>
                  <select
                    className="form-select"
                    aria-label="Choose Country"
                    name="billingCountry"
                  >
                    <option value="USA">United States of America</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4 mb-3">
                <div className="input-group">
                  <span className="input-group-text"><Globe /></span>
                  <StateField name="billingState"
                    className={'form-control' + (errors && errors['billingState'] && touched && touched['billingState'] ? ' is-invalid' : '')} />
                </div>
                {errors && <ErrorMessage name="billingState" component="div" className="invalid-feedback" />}
              </div>

              <div className="col-md-4">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName='billingZip'
                  placeholder='Zip Code'
                  icon={(<GeoAltFill />)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  );
}

export { CheckoutForm as StripeCheckoutForm }

