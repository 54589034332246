import React, { useEffect, useState } from 'react';

import {
  accountService,
} from '_services';

import {
  ISubscriber
} from '_interfaces';

import {
  Subscriptions,
  Transactions,
  CreditCardOnFile,
  Profile,
} from '..'


const Account = (props: any) => {

  const [transactions, setTransactions] = useState<any>([]);
  const [subscriptions, setSubscriptions] = useState<any>([]);
  const [profiles, setProfiles] = useState<any>([]);
  const [subscriber, setSubscriber] = useState<any>({} as ISubscriber);

  const updateSubscriber = (subscriber: ISubscriber) => {
    setSubscriber(subscriber);
  }

  useEffect(() => {

    Promise.all([
      accountService.getPayments(),
      accountService.getProfiles(),
      accountService.getSubscriptions(),
      accountService.getAccount(),
    ])
      .then((datasets: any) => {
        setTransactions(datasets[0].data);
        setProfiles(datasets[1]);
        setSubscriptions(datasets[2].data);
        setSubscriber(datasets[3].account)
      })
      .catch((error: any) => {
        console.error('error in Account', error);
        //messageService.sendMessage('error getting account datasets');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-8 offset-sm-2 mt-2">

          <Profile account={subscriber} updateSubscriber={updateSubscriber}/>

          <Subscriptions subscriptions={subscriptions} {...props} ></Subscriptions>

          <Transactions rows={transactions}></Transactions>

          <CreditCardOnFile rows={profiles}></CreditCardOnFile>

        </div>
      </div>
    </div>
  );
};

export { Account };
