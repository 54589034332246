import React from 'react';

interface ILink {
  label: string;
  url: string;
}

const Footer = () => {
  const links: ILink[] = [
    //{label: 'About Us', url: '/about-us'},
    {label: 'Terms and Conditions of Use', url: '/terms-of-use'},
    {label: 'Terms and Conditions of Sale', url: '/terms-of-sale'},
    {label: 'Privacy Policy', url: '/privacy-policy'},
    {label: 'Contact Us', url: '/contact-us'},
  ];

  return(
    <footer>
      <div className="container">
        <div className="row m-5 pt-2 justify-content-center border-top">
        {links.map( (link: ILink, i: number) => <div className="col-md-3 text-center" key={`link-${i}`}>
          <a href={link.url} className="text-primary fw-bold text-decoration-none">{link.label}</a>
          </div>)}
        </div>
      </div>
      <div className="row m-5">
          <div className="col text-center mt-5">
            Copyright 2021, EnjoyTheTown
          </div>
        </div>
    </footer>
  );
}

export { Footer }