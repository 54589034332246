import React from 'react';

const Stars:React.FunctionComponent<{count: number}> = ({count}) => {
  const items = [];
  for (let i=0; i < count; i++) {
    items.push(<span className="star" key={`star-${i}`}></span>);
  }
  return (
    <div className="star-container">
      {items}
    </div>
  );
}

export { Stars }