import React, { useEffect, useState } from 'react';

import {
  accountService,
} from '_services';

import {
  ISubscription,
  IOffer
} from '_interfaces';

const Subscriptions = (props: any) => {
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [autoRenewSubs, setAutoRenewSubs ] = useState<ISubscription[]>([]);
  const [offer, setOffer] = useState<IOffer>({} as IOffer)

  useEffect(() => {
    const autoRenewals: ISubscription[] = props.subscriptions.filter( (sub: ISubscription) => sub.cancelDate === null && sub.nextBillDate !== null);
    setSubscriptions(props.subscriptions);
    setAutoRenewSubs(autoRenewals);
    if (autoRenewals.length > 0 && autoRenewals[0].planId) {
      accountService.getOffer()
      .then( (response: any) => {
        setOffer(response);
      })
      .catch( (error: any) => {
        console.error('could not get offer', error);
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subscriptions]);

  const onClickSubscribe = () => {
    window.location.href = '/subscription';
  }

  const onClickCancel = (subscription: ISubscription) => {
    if (window.confirm("You are about to cancel your subscription. Click OK to confirm.")) {
      accountService.cancelSubscription(subscription)
        .then((data: any) => {
          accountService.getSubscriptions()
          .then( (dataset:any) => {
            setSubscriptions(dataset.data.data);                              
          })
          .catch( (error:any) => {
            setSubscriptions([]);
          })
        })
        .catch((error: any) => {
          console.error('error canceling', error);
        })
    }
  }

  const renderCurrentSubscription = () => {
    const today = new Date();
    console.log('in render', subscriptions, today.toISOString().substr(0,10));
    const canceledActive: ISubscription[] = subscriptions.filter( (sub: ISubscription) => sub.cancelDate !== null 
        && sub.endDate !== undefined
        && sub.endDate >= today.toISOString().substr(0,10));

    const neverCanceledButActive: ISubscription[] = subscriptions.filter( (sub: ISubscription) => sub.cancelDate === null 
        && sub.endDate !== undefined
        && sub.endDate >= today.toISOString().substr(0,10));


    // types of subscription
    // auto-renew
    // canceled, not expired
    // canceled, expired

    // for no active subscription
    if (autoRenewSubs.length === 0 && canceledActive.length === 0 && neverCanceledButActive.length === 0) {
      return <div className="card-body">
        <p>You do not have an active subscription</p>
        <button className="btn btn-primary" onClick={onClickSubscribe}>Buy a Subscription</button>
        </div>
    }

    // this is for active renewable subscription
    if (autoRenewSubs.length > 0) {
      return <div className="card-body">
        <p><b>Your Active Subscription</b>: {autoRenewSubs[0].planName}</p>
        <p>It will auto renew on {autoRenewSubs[0].nextBillDate} for the amount of ${offer.cost}.</p>
        <p>If you cancel your subscription today, you will still be able to access it until {autoRenewSubs[0].endDate}.</p>
        <button className="btn btn-primary" onClick={() => {onClickCancel(autoRenewSubs[0])}}>Cancel Subscription</button>
      </div>
    }

    // this is for canceled subscription but still has time left
    if (canceledActive.length > 0 ) {
      return <div className="card-body">
        <p><b>Your Active Subscription</b>: {canceledActive[0].planName}.</p>
        <p>It will expire on {canceledActive[0].endDate}. You can buy a subscription now to avoid interrupted service. </p>
        <button className="btn btn-primary" onClick={onClickSubscribe}>Buy a Subscription</button>
      </div>
    }

    // this is for subscription that has time left, never canceled
    if (neverCanceledButActive.length > 0 ) {
      return <div className="card-body">
        <p><b>Your Active Subscription</b>: {neverCanceledButActive[0].planName}.</p>
        <p>It will expire on {neverCanceledButActive[0].endDate}.</p>
      </div>
    }    
  }

  const renderPriorSubscriptions = () => {
    const priorSubs: ISubscription[] = subscriptions.filter((subscription: ISubscription) => subscription.cancelDate !== null);
    if (priorSubs.length === 0) {
      return <></>
    }
    return(
      <div className="card-body">
      <b>Prior Subscriptions</b>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Subscription Name</th>
            <th>Start Date</th>
            <th>Cancellation Date</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          {priorSubs.map((subscription: any) =>
              <tr key={subscription.id}>
                <td>{subscription.planName}</td>
                <td>{subscription.startDate}</td>
                <td>{subscription.cancelDate}</td>
                <td>{subscription.endDate}</td>
              </tr>
          )}
        </tbody>
      </table>
    </div>
    );
  }


  return (
    <>
      <div className="card mt-2">
        <h4 className="card-header">Subscriptions</h4>

        {renderCurrentSubscription()}

        {renderPriorSubscriptions()}

      </div>

    </>);
};

export { Subscriptions };
