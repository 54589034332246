import React from 'react';

const ConversionPixel = () => {

  return (
    <iframe title="adblade" src="/js/conv.html" 
      frameBorder={0} 
      aria-hidden={true} 
      tabIndex={-1}
      style={{display: "none"}}></iframe>
  );

}

export { ConversionPixel }